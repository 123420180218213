var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.type === '2')?_c('div',{staticClass:"verify-img-out",style:({ height: (parseInt(_vm.setSize.imgHeight) + _vm.vSpace) + 'px' })},[_c('div',{staticClass:"verify-img-panel",style:({
      width: _vm.setSize.imgWidth,
      height: _vm.setSize.imgHeight,
    })},[_c('img',{staticStyle:{"width":"100%","height":"100%","display":"block"},attrs:{"src":_vm.backImgBase ? ('data:image/png;base64,' + _vm.backImgBase) : _vm.defaultImg,"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRefresh),expression:"showRefresh"}],staticClass:"verify-refresh",on:{"click":_vm.refresh}},[_c('i',{staticClass:"iconfont icon-refresh"})]),_c('transition',{attrs:{"name":"tips"}},[(_vm.tipWords)?_c('span',{staticClass:"verify-tips",class:_vm.passFlag ? 'suc-bg' : 'err-bg'},[_vm._v(_vm._s(_vm.tipWords))]):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"verify-bar-area",style:({
    width: _vm.setSize.imgWidth,
    height: _vm.barSize.height,
    'line-height': _vm.barSize.height
  })},[_c('span',{staticClass:"verify-msg",domProps:{"textContent":_vm._s(_vm.text)}}),_c('div',{staticClass:"verify-left-bar",style:({ width: (_vm.leftBarWidth !== undefined) ? _vm.leftBarWidth : _vm.barSize.height, height: _vm.barSize.height, 'border-color': _vm.leftBarBorderColor, transaction: _vm.transitionWidth })},[_c('span',{staticClass:"verify-msg",domProps:{"textContent":_vm._s(_vm.finishText)}}),_c('div',{staticClass:"verify-move-block",style:({ width: _vm.barSize.height, height: _vm.barSize.height, 'background-color': _vm.moveBlockBackgroundColor, left: _vm.moveBlockLeft, transition: _vm.transitionLeft }),on:{"touchstart":_vm.start,"mousedown":_vm.start}},[_c('i',{class:['verify-icon iconfont', _vm.iconClass],style:({ color: _vm.iconColor })}),(_vm.type === '2')?_c('div',{staticClass:"verify-sub-block",style:({
          'width': Math.floor(parseInt(_vm.setSize.imgWidth) * 47 / 310) + 'px',
          'height': _vm.setSize.imgHeight,
          'top': '-' + (parseInt(_vm.setSize.imgHeight) + _vm.vSpace) + 'px',
          'background-size': _vm.setSize.imgWidth + ' ' + _vm.setSize.imgHeight,
        })},[_c('img',{staticStyle:{"width":"100%","height":"100%","display":"block"},attrs:{"src":'data:image/png;base64,' + _vm.blockBackImgBase,"alt":""}})]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }