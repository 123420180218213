<template>
    <div class="dialogQR">
        <el-dialog title="" :visible.sync="internalDialogVisible" width="479px" :close-on-click-modal="false"
            @close="close">
            <div>
                <randomQR :path="path" :index="index"></randomQR>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import randomQR from "@/components/common/randomQR.vue";

export default {
    name: "dialogQR",
    components: {
        randomQR
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        path: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            internalDialogVisible: this.dialogVisible
        };
    },
    watch: {
        dialogVisible(newVal) {
            this.internalDialogVisible = newVal;
        }
    },
    methods: {
        close() {
            this.internalDialogVisible = false
            this.$emit('cancel');
        }
    }
}
</script>
<style lang='scss'>
.dialogQR {
    .el-dialog__body {
        padding-top: 10px;
    }
}
</style>