<template>
    <div class="piece_box">
        <div class="title_box">
            <div class="title">
                产品服务介绍
            </div>
            <div class="text">
                利用大数据，收集、分析，论文被引情况，把您的论文精准推送给大量可能引用的研究者，
                从而提升您的个人学术数据，提升学术影响力
            </div>
        </div>
        <div class="product">
            <div class="product_item pointer" @click="getToMenu('/paperPromotion')">
                <div class="product_title">
                    论文影响力推广
                </div>
                <div class="content">
                    通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升您的个人学术数据，提升学术影响力。
                </div>
            </div>
            <div class="product_item pointer" @click="getToMenu('/personalPromotion')">
                <div class="product_title">
                    个人影响力推广
                </div>
                <div class="content">
                    <ul>
                        <li>
                            H指数推广
                        </li>
                        <li>
                            全球高被引科学家培育
                        </li>
                        <li>
                            中国高被引学者培育
                        </li>
                        <li>
                            斯坦福顶尖2%科学家培育
                        </li>

                    </ul>
                </div>
            </div>
            <div class="product_item pointer" @click="getToMenu('/journalPromotion')">
                <div class="product_title">
                    期刊影响力推广
                </div>
                <div class="content">
                    <ul>
                        <li>
                            期刊运营指导，帮助新创期刊进行管理，代运营
                        </li>
                        <li>
                            帮助期刊进行征稿宣传
                        </li>
                        <li>
                            为期刊招募主编、客编等
                        </li>
                        <li>
                            期刊影响因子管理，提升期刊影响力"
                        </li>

                    </ul>
                </div>
            </div>
            <div class="product_item pointer" @click="getToMenu('/consult')">
                <div class="product_title">
                    高校学科建设咨询
                </div>
                <div class="content">
                    <ul>
                        <li>
                            了解本校学科与设定目标、对标高校的具体差距
                        </li>
                        <li>
                            分析生成学科报告，深度总结学科发展
                        </li>
                        <li>
                            制定建设方案
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {

        }
    },
    methods: {
        ...mapActions(['updateSharedData']),
        getToMenu(data) {
            this.updateSharedData('/promotion')
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'  // 可选，实现平滑滚动
            });
            this.handleSelect(data)
        },
        // 导航栏跳转
        handleSelect(index) {
            // 获取当前路由的路径
            const currentPath = this.$route.fullPath;

            // 如果当前路由路径不等于目标路由路径，则进行路由跳转
            if (currentPath !== index) {
                this.$router.push(index);

            } else {
                // 当前路由路径与目标路由路径相同，无需重复导航
                console.log('Already on the same route');
            }
        },
    }
}
</script>

<style scoped lang="scss">
.piece_box {
    width: 100%;
    margin: 0 auto;
    // padding: 40px;
    box-sizing: border-box;

    .title_box {
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;


        .title {
            color: #3470ff;
            font-size: 32px;
            font-weight: bold;
            // line-height: 50px;
            padding-bottom: 20px;
        }

        .text {
            color: #434343;
            font-style: italic;
            font-size: 18px;
            width: 600px;
            text-align: center;
        }
    }
}

// 产品介绍
.product {
    display: flex;
    // align-items: center;
    max-width: 1623px;
    justify-content: center;
    margin: 0 auto;

    .product_item {
        position: relative;
        /* 确保伪元素可以定位 */
        width: calc(calc(100% - 600px) /4);
        // width: 200px;
        min-height: 275px;
        // height: 100%;
        padding: 20px;
        margin: 17px;
        background-image: url('@/assets/image/product_bg.png');
        // background-color: #000;
        background-size: cover;
        /* 让背景图片铺满整个元素 */
        background-position: center;
        /* 图片居中对齐 */
        background-repeat: no-repeat;

        .product_title {
            position: relative;
            font-size: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-bottom: 30px;
            color: #fff;
            z-index: 999;
            padding: 20px;
            text-wrap: nowrap;
        }

        .content {
            position: relative;
            z-index: 999;
            color: #fff;

            ul {
                line-height: 32px;
                padding: 0 20px;
            }
        }
    }

    /* 奇数项（1, 3, 5, ...）添加上边框 */
    .product_item:nth-child(odd) {
        border-bottom: 5px solid #3470FF;
        /* 添加上边框，蓝色，宽度为3px */
    }

    /* 偶数项（2, 4, 6, ...）添加下边框 */
    .product_item:nth-child(even) {
        border-top: 5px solid #3470FF;
        /* 添加下边框，蓝色，宽度为3px */
    }

    .product_item::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(67, 67, 67, 0.5);
        /* 半透明的颜色叠加层 */
        // filter: blur(5px);
        /* 模糊效果 */
        z-index: 1;
        /* 确保在背景图片之上 */
        transition: all 0.5s;
    }

    .product_item:hover::before {
        background-color: rgba(73, 73, 160, 0.5);
        /* 鼠标移入时的颜色 */
    }
}
</style>
