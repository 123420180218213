<template>
  <div id="app">
    <header-menu v-if="show"></header-menu>

    <router-view v-if="isRouterAlive"></router-view>

    <Bottom v-if="show"></Bottom>

  </div>
</template>
<script>
import headerMenu from './components/common/header.vue';

import Bottom from './components/common/bottom.vue';
// import backTop from '@/components/common/backTop'
export default {
  name: 'App',
  data() {
    return {
      //路由重载
      isRouterAlive: true
    }
  },
  components: {
    headerMenu,
    Bottom,
    // backTop
  },
  computed: {
    show() {
      // 获取当前路由的路径
      const currentPath = this.$route.path;
      // 根据当前路径来判断是否显示导航菜单
      return currentPath !== '/login' && currentPath !== '/personal'; // 例如，当路径不是 '/login' 时显示导航菜单
    }
  },
  created() {
    localStorage.removeItem('path')
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style>
body {
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
  min-width: 1623px;
  box-sizing: border-box;
  /* text-align: center; */
}

.pointer {
  cursor: pointer
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>