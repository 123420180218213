<template>
    <div class="scroll">
        <div class="title_box">
            <div class="title">
                今日优秀论文推荐
            </div>
            <div class="text">
                为您推荐最匹配优秀论文，每日自动更新10-20篇论文
            </div>
        </div>

        <div class="section">
            <img src="@/assets/image/recommend_icon1.png" alt="" class="icon_1">
            <el-card class="box_card">
                <el-scrollbar wrap-class="scroll-wrapper" ref="scrollbar1">
                    <div class="list">
                        <div class="item" v-for="(item, index) in 10">
                            <div class="journal_title">
                                {{ index + 1 }}. Persistence of genetically engineered canola populations in the U.S.
                                and
                                the adventitious presence of transgenes in the environment.
                            </div>
                            <div class="journal_text">
                                Steven E. Travers ,D. Bryan Bishop,Cynthia L. Sagers
                            </div>
                            <div class="journal_url">
                                https://doi.org/10.1371/journal.pone.0295489
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </el-card>
            <img src="@/assets/image/recommend_icon2.png" alt="" class="icon_2">
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {


    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.scroll {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('@/assets/image/paper_bg.jpg');

    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .title_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-size: 32px;
            color: #3470ff;
            padding: 28px;
            font-weight: bold;
        }

        .text {
            font-style: italic;
            line-height: 30px;
            letter-spacing: 0px;
            color: #434343;
            font-size: 20px;
        }
    }

    .box_card {
        width: 950px;
        height: 514px;

        padding: 68px 32px;
        box-sizing: border-box;
        position: relative;

        .list {
            height: 400px;

            .item {
                padding: 0 32px 32px;
            }

            .journal_title {
                font-size: 18px;
                padding-bottom: 21px;
            }

            .journal_text {
                font-size: 16px;
            }

            .journal_url {
                font-size: 14px;
                color: #666666;
            }
        }
    }

    .section {
        // width: 100%;
        margin: 79px auto;
        position: relative;
    }

    .icon_1 {
        position: absolute;
        top: 0;
        left: 10px;
        transform: translateY(-30%);
        z-index: 99;
    }

    .icon_2 {
        position: absolute;
        bottom: 0;
        right: 10px;
        transform: translateY(30%);
        z-index: 99;
    }
}
</style>
<style scoped></style>