<template>
	<div class="bottom">
		<main class="main">
			<div class="down_bg" v-if="headerActive !== '/report'">
				<div class="down_box">
					<div class="down_left">
						<div class="down_title">
							即刻下载，马上体验
						</div>
						<div class="hint">
							支持最新Android 8.0 及以上系统
						</div>
						<!-- <div class="down_btn">
							免费使用
						</div> -->
						<el-button plain @click="downloadExternalFile" class="down_btn_">免费使用</el-button>
					</div>
					<div class="down_right">
						<div class="QR_box pointer">
							<el-dropdown placement="top">
								<div class="right_box">
									<span class="iconfont icon-anzhuo" style="color: #549E51;"></span>
									<div class="">
										Android下载
									</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="QR_img">
										<img src="@/assets/image/app_QR.png" alt="">
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="QR_box pointer">
							<el-dropdown placement="top">
								<div class="right_box">
									<span class="iconfont icon-weixin" style="color: #549E51;"></span>
									<div class="">
										微信公众号
									</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="QR_img">
										<img src="../../assets/image/gaibeiyin_QR.jpg" alt="">
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
			</div>
			<div class="spread_bg">
				<div class="spread_box">
					<div class="spread_left">
						<div class="phone">
							<div>
								service@highlycitedpaper.com
							</div>
							<div class="time">
								(工作日 08:30-18:00)
							</div>
						</div>
						<div class="list">
							<div class="spread_item">
								<div class="spread_title">
									推广服务
								</div>
								<div @click="getToMenu('/promotion')">
									<router-link to="/paperPromotion">论文影响力推广</router-link>
								</div>
								<div @click="getToMenu('/promotion', '/personalPromotion')">
									<router-link to="/personalPromotion">个人影响力推广</router-link>
								</div>
								<div @click="getToMenu('/promotion', '/journalPromotion')">
									<router-link to="/journalPromotion">期刊影响力推广</router-link>
								</div>
								<div @click="getToMenu('/promotion', '/consult')">
									<router-link to="/consult">高校学科建设咨询</router-link>
								</div>
							</div>
							<div class="spread_item">
								<div class="spread_title">
									帮助支持
								</div>
								<div @click="getToMenu('/trade?page=0', '/trade?page=0')">
									<router-link to="/trade?page=0">干货&资讯</router-link>
								</div>
								<!-- <div>
									常见问题
								</div> -->

							</div>
							<div class="spread_item">
								<div class="spread_title">
									关于我们
								</div>
								<div @click="getToMenu('/about', '/about')">
									<router-link to="/about">关于我们</router-link>
								</div>
								<div @click="getToMenu('/cooperation', '/cooperation')">
									<router-link to="/cooperation">商务合作</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="spread_right">
						<div class="spread_r_item pointer">
							<el-dropdown placement="top">
								<div class="right_box">
									<span class="iconfont icon-icon_"></span>
									<div class="down_r_name"></div>
									<div class="">
										高被引App
									</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="QR_img">
										<img src="@/assets/image/app_QR.png" alt="">
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="spread_r_item pointer">
							<el-dropdown placement="top">
								<div class="right_box">
									<span class="iconfont icon-weixin" style="color: #549E51;"></span>
									<div class="">
										箭弩学术公众号
									</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="QR_img">
										<img src="../../assets/image/jiannu.jpg" alt="">
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="spread_r_item pointer">
							<el-dropdown placement="top">
								<div class="right_box">
									<span class="iconfont icon-weixin" style="color: #549E51;"></span>
									<div class="">
										高被引公众号
									</div>
								</div>
								<el-dropdown-menu slot="dropdown">
									<div class="QR_img">
										<img src="../../assets/image/gaibeiyin_QR.jpg" alt="">
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</div>

					</div>
				</div>
			</div>
			<div class="youqing">
				友情链接：
				<a href="https://www.journal.ac.cn/" target="_blank">JDC箭弩学术</a>
				<a href="https://www.contrimetric.com/" target="_blank">Contrimetric</a>
				<a href="https://www.openjournalsystems.my/" target="_blank">OJS Malaysia</a>
				<a href="https://www.sciences.ac.cn/" target="_blank">论译</a>
				<a href="http://www.rjlx.com.cn/" target="_blank">睿金留学</a>
				<a href="http://www.highlycitedresearcher.com/" target="_blank">高被引科学家</a>
			</div>
			<div class="base_box">
				<div class="logo">
					<img src="../../assets/image/logo.png" alt="">
				</div>
				<div class="text">
					<span>Copyright© 2020 深圳睿金易美科技有限公司｜<a href="http://beian.miit.gov.cn"
							target="_blank">粤ICP备2021113231号-5</a> </span>
					<span class="">
						<span class="pointer" @click="goToProvacy" style="margin-left: 10px;">隐私政策</span> | <span class="pointer"  @click="goToUserAg">用户服务协议</span>
					</span>
				</div>
			</div>
		</main>
	</div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
export default {
	computed: {
		...mapState(['headerActive']),
	},
	data() {
		return {

		};
	},
	mounted() {

	},
	methods: {

		...mapActions(['updateSharedData']),
		getToMenu(index, data) {
			this.updateSharedData(index)
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'  // 可选，实现平滑滚动
			});
		},
		downloadExternalFile() {
			window.location.href = 'http://share.mostcitedpaper.com/downloadLocal?fileName=HighlyCited.apk';

		},
		goToProvacy() {
			this.$router.push('/privacy')

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		},
		goToUserAg() {
			this.$router.push('/userAgreement')

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

};
</script>
<style scoped lang="scss">
.bottom {
	width: 100%;
	display: flex;
	flex-direction: column;
	// margin-bottom: 100px;

	.down_bg {
		background-color: #1249CA;
	}

	.down_box {
		margin: 0 auto;
		// width: 1200px;
		display: flex;
		justify-content: space-evenly;
		padding: 62px;

		.down_left {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			// line-height: 30px;

			.down_title {
				font-size: 36px;
				color: #fff;
				font-weight: 500;
			}

			.hint {
				font-size: 16px;
				color: #98ADEC;
				margin: 20px 0 30px;

			}

			.down_btn_ {
				transform: scale(1.1);
			}

			.down_btn {
				padding: 10px 20px;
				background-color: #fff;
				color: #3b3a3a;
				font-size: 14px;
			}
		}

		.down_right {
			display: flex;
			align-items: center;

			.QR_box {
				margin: 0 10px;
				width: 120px;
				height: 120px;
				// border: 1px solid #000;
				border-radius: 10px;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				line-height: 40px;

				.right_box {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					line-height: 40px;

					span {
						font-size: 50px;
					}

				}

				span {
					font-size: 38px;
					// color: #fff;
				}

				.down_r_name {
					font-size: 14px;
					color: #666;
				}

			}

			.QR_box:nth-of-type(1)>span {
				color: #08C873;
			}

			.QR_box:nth-of-type(2)>span {
				color: #549E51;
			}
		}
	}

	.spread_bg {
		background-color: #fff;

		.spread_box {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 20px;
			// justify-content: center;
			align-items: flex-end;

			.spread_left {
				// flex: 3;
				display: flex;
				align-items: center;

				/* >div{
					width: calc(calc(100% - 100px) / 4);
				} */
				.list {
					display: flex;
				}

				.spread_item {
					line-height: 40px;
					margin: 0 30px;
					font-size: 16px;
					color: #666;

					.spread_title {
						font-size: 24px;
						font-weight: 500;
						color: #000;
					}
				}

				.phone {
					color: #121F2D;
					font-size: 16px;
					font-weight: 600;
					white-space: nowrap;
					margin-right: 55px;
					text-align: center;

					.time {
						margin-top: 10px;
						font-size: 14px;
						color: #8A8A8A;
						font-weight: normal;
					}
				}
			}

			.spread_right {
				display: flex;
				align-items: center;

				.right_box {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					line-height: 40px;

					span {
						font-size: 50px;
					}

				}

				.spread_r_item {
					margin-left: 30px;
				}


			}
		}
	}

	.youqing {
		font-size: 14px;
		color: #919191;
		width: 1200px;
		margin: 0 auto;
		border-bottom: 1px solid #000;
		padding: 20px;

		a {
			margin-right: 10px;
		}
	}

	.base_box {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;

		.logo {
			width: 120px;
			margin-right: 20px;

			img {
				width: 100%;
			}
		}

		.text {
			font-size: 14px;
			color: #434343;

			span:nth-child(2) {
				font-weight: bold;
			}
		}


	}
}
</style>
<style scoped>
.el-popper {
	width: 140px;
	height: 120px;
	display: flex;
	justify-content: center;
}

.QR_img {
	width: 120px;
	height: 120px;
}

.QR_img img {
	width: 100%;
	height: 100%;
}
</style>
<style>
.router-link-active {
	text-decoration: none;
	color: #666;
}

a {
	text-decoration: none;
	color: #666;
}
</style>