<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        商务合作
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/cooperation_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="section">
                <div class="section_box">
                    <div class="left">
                        <div class="title">
                            <span class="iconfont icon-weixin" style="color: #549E51;"></span>
                            <div class="">
                                添加微信洽谈合作
                            </div>
                        </div>
                        <div class="QR_img">
                            <image-list :imageType="14"></image-list>
                        </div>
                        <div class="left_top top"></div>
                        <div class="top_left top"></div>
                        <div class="left_top bottom"></div>
                        <div class="top_left bottom"></div>
                    </div>
                    <div class="right">
                        <div class="icon">

                            <img class="icon_img" src="@/assets/image/11_07.png" alt="">

                            <div>
                                国际会议
                            </div>
                        </div>
                        <div class="info">
                            <div class="name">
                                王老师
                            </div>
                            <div class="phone">
                                电话：19137184507
                            </div>
                            <div class="email">
                                邮箱：1851697455@qq.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section1">
                <div class="section_box reversal">
                    <div class="left">
                        <div class="title">
                            <span class="iconfont icon-weixin" style="color: #549E51;"></span>
                            <div class="">
                                添加微信洽谈合作
                            </div>
                        </div>
                        <div class="QR_img">
                            <image-list :imageType="13"></image-list>
                        </div>
                        <div class="left_top top"></div>
                        <div class="top_left top"></div>
                        <div class="left_top bottom"></div>
                        <div class="top_left bottom"></div>
                    </div>
                    <div class="right">
                        <div class="icon">
                            <div>
                                <img class="icon_img" src="@/assets/image/11_07.png" alt="">
                            </div>
                            <div>
                                期刊发展
                            </div>
                        </div>
                        <div class="info">
                            <div class="name">
                                郭老师
                            </div>
                            <div class="phone">
                                电话：18026997165
                            </div>
                            <div class="email">
                                邮箱：assistant.littlekristine@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>

        <journal-recommend></journal-recommend>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import imageList from '@/components/common/imageList.vue'
export default {
    components: {
        journalRecommend,
        imageList
    },
    data() {
        return {
            activeName: 'first'
        }
    },
    created() {

    },
    mounted() {


    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 487px;
        height: 431px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;

        }

    }
}

main {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;

    .section {
        display: flex;
        flex-direction: column;
        margin: 100px 0;

        .reversal {
            flex-direction: row-reverse;
        }

        .section_box {
            display: flex;
            justify-content: space-between;

            >div {
                width: calc(calc(100% - 100px) / 2);
            }

            .left {
                position: relative;
                height: 292px;
                background-color: #f9f9f9;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 5px 5px rgba(147, 138, 138, 0.2);

                .title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;

                    .iconfont {
                        font-size: 52px;
                    }

                    >div {
                        padding: 20px;
                        color: #434343;
                        font-size: 14px;
                    }
                }

                .QR_img {
                    width: 156px;
                    height: 156px;
                    /*  background-color: #ccc; */
                }

                .left_top {
                    width: 200px;
                    height: 3px;
                    background-color: #3470ff;
                }

                .top_left {
                    width: 3px;
                    height: 98px;
                    background-color: #3470ff;
                }

                .top {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .bottom {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: #3470ff;

                    .icon_img {
                        width: 34px;
                        height: 42px;
                        margin-right: 10px;
                    }

                }

                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px;
                    margin-left: 50px;
                    line-height: 30px;
                    font-size: 14px;

                    .name {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }

        }
    }
}
</style>
<style scoped></style>