<template>
    <div class="randomQR_" :style="{ 'flex-direction': isFlexDir ? 'column' : 'row' }">
        <div class="left_box" v-if="isShowText">
            <div class="icon">
                <span class="iconfont icon-weixin" style="color: #549E51;"></span>
            </div>
            <div class="text">
                {{ text1 }}
            </div>
            <div class="text2">
                {{ text2 }}
            </div>
        </div>
        <div class="right_box">
            <img :src="imageSrc" alt="">
        </div>
    </div>
</template>
<script>
import { dealWithQRByPath } from "./getRandomQR"

export default {
    props: {
        path: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        isShowText: {
            type: Boolean,
            default: true
        },
        isFlexDir: {
            type: Boolean,
            default: false
        },
        text1: {
            type: String,
            default: '添加客服好友'
        },
        text2: {
            type: String,
            default: '获取报价'
        }
    },
    data() {
        return {
            imageSrc: ''
        };
    },
    mounted() {
        this.imageSrc = dealWithQRByPath(this.path, this.index)
    }
};
</script>
<style lang='scss'>
.randomQR_ {
    display: flex;
    justify-content: center;
    padding: 30px;
    padding-top: 20px;

    >div {
        width: 156px;
        height: 156px;
    }

    .left_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 30px;
        color: #666666;
        font-size: 14px;
        width: auto;
        margin-right: 24px;

        .text,
        .text2 {
            font-size: 16px;
            color: #434343;
        }

        span {
            font-size: 54px;
        }
    }

    .right_box {
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>