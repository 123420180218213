// 图片数组
const images = [
    require('@/assets/QR_img/傅.png'),
    require('@/assets/QR_img/黄.jpg'),
    require('@/assets/QR_img/李.jpg'),
    require('@/assets/QR_img/丘.jpg'),
    require('@/assets/QR_img/沈-1.jpg'),
    require('@/assets/QR_img/孙.png'),
    require('@/assets/QR_img/王.png'),
    require('@/assets/QR_img/徐.jpg'),
    require('@/assets/QR_img/余.jpg'),
    require('@/assets/QR_img/张.jpg'),
    require('@/assets/QR_img/张古存.jpg'),
    require('@/assets/QR_img/郑.jpg'),
]

// 获取当前日期 2024-08-20
const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

// 0-12 随机整数
const getRandomInt = (min = 0, max = images.length - 1) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

// 生成唯一的 activeIndex
const getRandomUniqueIndex = (dataArr) => {
    let activeIndex;
    do {
        activeIndex = getRandomInt();
    } while (dataArr.some(item => item && item.activeIndex === activeIndex));

    return activeIndex;
}

/**
 * @description 根据路径获取随机二维码 2024.08.20 16:32
 * @param {*} path 页面路径 组件 this.$route.path
 * @param {*} index 页面引用索引 第1个引用为0 第2个为1 以此类推 同一页面的index 不能一样
 * @example <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="0" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
 */
export const dealWithQRByPath = (path, index) => {
    const data = localStorage.getItem(path)

    // 如果对应路径没有数据 则创建数据
    if (data == null) {
        const activeIndex = getRandomInt()
        localStorage.setItem(path, JSON.stringify([{ time: getCurrentDate(), activeIndex }]))

        return images[activeIndex]
    }

    const dataArr = JSON.parse(data)

    // 如果对应索引 没有数据 则创建数据
    if (dataArr[index] == null || dataArr[index] == void (0)) {
        const activeIndex = getRandomUniqueIndex(dataArr)
        dataArr[index] = { time: getCurrentDate(), activeIndex }
        localStorage.setItem(path, JSON.stringify(dataArr))

        return images[activeIndex]
    }

    // 如果对应索引有数据 则判断当前日期是否与上次日期相同 如果不同 则重新生成数据
    if (dataArr[index].time !== getCurrentDate()) {
        const activeIndex = getRandomUniqueIndex(dataArr)
        dataArr[index].time = getCurrentDate()
        dataArr[index].activeIndex = activeIndex
        localStorage.setItem(path, JSON.stringify(dataArr))

        return images[activeIndex]
    }

    // 如果相同 直接返回上次的索引
    return images[dataArr[index].activeIndex]
}