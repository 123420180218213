<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        个人影响力<span>推广</span>
                    </div>
                    <div class="center">
                        通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升
                        您的个人学术数据，提升学术影响力
                    </div>
                    <div class="btn">
                        <el-button class="top_btn" @click="openRandomDialog">开始推广</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/personage.png" alt="">
                </div>
            </div>
        </div>
        <main>



            <div class="section section_bg1">
                <div class="title">
                    H指数推广
                </div>
                <div class="center">
                    H-index，又称为h指数或h因子（h-factor），是一种评价学术成就的新方法。h代表“高引用次数”（high
                    citations），一名科研人员的h指数是指他至多有h篇论文分别被引用了至少h次。h指数能够比较准确地反映一个人的学术成就。一个人的h指数越高，则表明他的论文影响力越大。
                </div>
                <div class="icon">
                    <span class="iconfont icon-king"></span> H指数管理
                </div>
                <div class="bottom">
                    制定详细的H指数管理方案，通过大数据精准提高个人影响力。
                </div>
            </div>


            <div class="section  section_bg2">
                <randomQR :path="$route.path" :index="0" :isFlexDir="true" :text2="'获取分析报告'" class="bottom1"></randomQR>
                <div class="title">
                    全球高被引科学家培育
                </div>
                <div class="center">
                    科睿唯安的“全球高被引科学家”名单每年评选一次，旨在表彰对所在学科做出重大贡献的具有全球影响力的自然科学家和社会科学家，在全球所有的自然科学和社会科学领域研究者中，“全球高被引科学家”仅占千分之一。
                </div>
                <div class="icon">
                    <span class="iconfont icon-king"></span> 榜单公布时间：每年的11月份
                </div>
                <div class="icon">
                    <span class="iconfont icon-24gf-tags"></span> 遴选标准
                </div>
                <div class="bottom">

                    <div>
                        1. 高被引论文数量在全球学者中占比前1%。
                    </div>
                    <div>
                        2. 条件一中的高被引论文的总被引次数达到全球前1%。
                    </div>

                    <div>
                        3. 排除存在学术不端的学者，如极端数量的论文署名、过度自引、合作团体引用活动的异常模式及合著作者的异常引用表现。
                    </div>
                    <div>
                        4. 从2023年开始暂时剔除ESI学科为数学的论文参与榜单评选范围。
                    </div>
                </div>
            </div>
            <div class="section  section_bg1">
                <randomQR :path="$route.path" :index="1" :isFlexDir="true" :text2="'获取分析报告'" class="center1"></randomQR>
                <div class="title">
                    中国高被引学者培育
                </div>
                <div class="center">
                    爱思唯尔“中国高被引学者年度榜单”是以全球领先的信息分析公司爱思唯尔(Elsevier)的引文与索引数据库Scopus作为统计来源，采用了上海软科教育信息咨询有限公司开发的方法，从多个维度深度剖析、识别处于科研职业生涯不同时期的中国学者、并系统性展示其科研成果表现。
                </div>
                <div class="icon">
                    <span class="iconfont icon-king"></span>榜单公布时间：每年的4-5月份
                </div>
                <div class="icon">
                    <span class="iconfont icon-24gf-tags"></span> 遴选标准
                </div>
                <div class="bottom">
                    <div>
                        1. Scopus收录的科研成果发表署名机构为中国（大陆）机构的作者，且现职工作单位在中国，包括非中国籍和非华裔学者。
                    </div>
                    <div>
                        2. 仅统计上述学者作为第一作者或通讯作者发表的科研成果。
                    </div>
                    <div>
                        3. 根据Scopus教育部一级学科分类体系进行上述学者的科研成果分类。每个学科上榜的学者数量与Scopus收录该学科中国机构署名的作者数量相关。
                    </div>
                    <div>
                        4. 在榜单统计的上述学者（第一作者或通讯作者文献）需要满足：
                    </div>
                    <div>
                        a. 被收录文献数一篇以上（不含）；
                        b. 被收录文献的总被引次数达到本学科阈值条件；
                        c. 至少有一篇全球前1%高被引文献，或学者作为第一作者或通讯作者发文整体FWCI高于1。
                    </div>
                </div>

            </div>
            <div class="section section_bg2">
                <randomQR :path="$route.path" :index="2" :isFlexDir="true" :text2="'获取分析报告'" class="bottom1"></randomQR>
                <div class="title">
                    斯坦福顶尖2%科学家培育
                </div>
                <div class="center">
                    美国斯坦福大学John P. A. Ioannidis教授团队和爱思唯尔数据库（Elsevier Data Repository）发布2023全球前2％顶尖科学家榜单（Stanford
                    University Top 2 Scientists List）。发布在Elsevier旗下的Mendeley Data网站上。
                </div>
                <div class="icon">
                    <span class="iconfont icon-king"></span> 榜单公布时间：每年的10月份
                </div>
                <div class="icon">
                    <span class="iconfont icon-24gf-tags"></span> 遴选标准
                </div>
                <div class="bottom">
                    榜单基于Scopus数据库的引用数据系统分析，以被引数（区分自引和他引，提供自引数据）、H-index、HM因子等6种综合指标(compositescore，C-score)，根据「生涯影响力」和「年度影响力」从近700万名科学家中遴选出世界排名前2%的科学家，分为22个领域和176个子学科领域，为科学家长期科研表现提供了一个衡量指标，能够更客观、更真实地反映科学家的影响力。
                </div>
            </div>
        </main>
        <journal-recommend></journal-recommend>
        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="3" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import addService from './components/addService.vue'
import journalRecommend from "@/components/common/journalRecommend.vue"
import dialogQR from "@/components/common/dialogQR.vue";
import randomQR from '@/components/common/randomQR.vue';

export default {
    components: {
        journalRecommend,
        addService,
        dialogQR,
        randomQR
    },
    data() {
        return {
            dialogVisible: false,
            activeName: 'first'
        }
    },
    created() {

    },
    mounted() {


    },
    methods: {
        openRandomDialog() {
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.center1 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    border-radius: 10px 0px 0px 10px;
}

.bottom1 {
    position: absolute;
    right: 0;
    bottom: 50px;
    transform: translateX(100%);
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 487px;
        height: 431px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;

            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    // margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    position: relative;

    .section {
        position: relative;
        width: 100%;
        margin-bottom: 50px;
        padding: 20px 0;
        box-sizing: border-box;
        line-height: 30px;

        .title {
            font-size: 30px;
            padding: 20px 56px;
        }

        .center {
            padding: 20px 56px;
        }

        .icon {
            padding: 10px 36px;
        }

        .bottom {
            padding: 20px 56px;

        }
    }

    .section_bg1 {
        background-color: #434343;
        border-right: 5px solid #3470ff;
        color: #979797;

        .title {
            color: #fff;
        }
    }

    .section_bg2 {
        background-image: linear-gradient(0deg,
                #5d3bdd 0%,
                #07367e 100%),
            linear-gradient(#434343,
                #434343);
        background-blend-mode: lighten,
            normal;
        border-left: 5px solid #3470ff;
        color: #c5d3f8;

        .title {
            color: #c5d3f8;
        }
    }

}
</style>
<style scoped></style>