<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        {{  activeName=== '2' ? "行业资讯" : "行业干货" }}
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/trade_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name="0">
                    <div class="tabs">
                        <industry-news></industry-news>
                        <!-- <industryRight class="sticky"></industryRight> -->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="行业干货" name="1">
                    <div class="tabs">
                        <industry-news></industry-news>
                        <!-- <industryRight class="sticky"></industryRight> -->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="行业资讯" name="2">
                    <div class="tabs">
                        <industry-news></industry-news>
                        <!-- <industryRight class="sticky"></industryRight> -->
                    </div>
                </el-tab-pane>
            </el-tabs>
            <industryRight class="sticky"></industryRight>
        </main>
        <journal-recommend></journal-recommend>
    </div>
</template>
<script>
import industryNews from "./components/industryNews.vue"
import industryRight from "./components/industryRight.vue"
import journalRecommend from "@/components/common/journalRecommend.vue"
import { mapState, mapActions } from 'vuex';
export default {
    computed: {
        ...mapState(['treadeIndex']),
    },
    components: {
        // mySwiper
        industryNews,
        industryRight,
        journalRecommend
    },
    data() {
        return {
            activeName: '0'
        }
    },
    created() {

    },
    watch: {
        '$route'(to, from) {
            let { page } = this.$route.query
            if (page) {
                this.activeName = page
            } else {
                this.page = '0'
            }
        }
    },
    mounted() {

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 150px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 487px;
        height: 431px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;

        }

    }
}

main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 50px;
    justify-content: space-between;

    .tabs {
        display: flex;
        justify-content: space-between;
    }
}
</style>
<style scoped>
::v-deep .el-tabs__nav-wrap::after {
    opacity: 0;
}

::v-deep .el-tabs__item {
    font-size: 18px;
}
</style>