<template>
    <div>
        <div v-if="modeLogin === '密码登录'">
            <div class="title_box">
                <div class="title pointer">
                    <div>账号登录</div>
                </div>
                <div style="padding: 0 20px;">|</div>
                <div class="min_title pointer" @click="codeLogin">
                    验证码登录
                </div>
            </div>

            <div class="login_box">
                <el-form class="form" label-position="top" :model="form" :rules="rules" ref="form" v-loading="loading">
                    <el-form-item required prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入手机号" @keyup.enter.native="getToLogin('form')">
                            <i slot="prefix" class="el-icon-mobile-phone"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item required prop="password">
                        <el-input placeholder="请输入密码" v-model="form.password" show-password
                            @keyup.enter.native="getToLogin('form')">
                            <i slot="prefix" class="el-icon-lock"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <div style="float: right; font-size: 14px;color: #9e9e9e;  cursor: pointer; " @click="forget">
                            <span>
                                忘记密码?
                            </span>
                        </div>
                        <el-button type="primary" @click="getToLogin('form')" style="width: 100%">登录</el-button>
                        <div style="float: right; font-size: 14px;color:#0076FE; font-weight: bold;  cursor: pointer;"
                            @click="register">
                            <span>
                                立即注册
                            </span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div v-if="modeLogin === '验证码登录'">
            <div class="title_box">
                <div class="title pointer">
                    验证码登录
                </div>
                <div style="padding: 0 20px;">|</div>
                <div class="min_title pointer" @click="passwordLogin">
                    密码登录
                </div>
            </div>
            <div class="login_box">
                <el-form class="form" label-position="top" :model="form" :rules="rules" ref="form" v-loading="loading">
                    <el-form-item required prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入手机号" @keyup.enter.native="CodeLogin('form')">
                            <i slot="prefix" class="el-icon-mobile-phone"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item required prop="code">
                        <el-input v-model="form.code" placeholder="请输入验证码">
                            <i slot="prefix" class="el-icon-key"></i>
                            <template #suffix>
                                <el-button type="text" @click="getCode(form)" :disabled="getCodeDisabled">
                                    {{ getCodeText }}
                                </el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <div style="float: right; font-size: 14px;color: #9e9e9e;  cursor: pointer; " @click="forget">
                            <span>
                                忘记密码?
                            </span>
                        </div>
                        <el-button type="primary" @click="CodeLogin('form')" style="width: 100%">登录</el-button>
                        <div style="float: right; font-size: 14px;color:#0076FE; font-weight: bold;  cursor: pointer;"
                            @click="register">
                            <span>
                                立即注册
                            </span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <Verify @success="success" :mode="'pop'" :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
    </div>
</template>
<script>
import { getLogin, getRegisterCode, getCodeLogin } from "@/api/login/login"
import Verify from "@/components/verifition/Verify.vue"

const TIME_COUNT = 120;
export default {
    components: {
        Verify
    },
    data() {
        //校验手机号
        var userName2 = (rule, value, callback) => {
            const phoneReg = /^1(3[0-9]|4[579]|5[0-35-9]|6[56]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
            if (!value) {
                return callback(new Error('请输入手机号'))
            }
            setTimeout(() => {
                if (phoneReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入正确手机号'))
                }
            }, 100)
        };
        //校验验证码
        var code1 = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请输入验证码"));
            } else {
                callback();
            }
        };
        //校验密码
        var validatePass = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请输入密码"));
            } else if (value.length < 6) {
                callback(new Error("请输入6位以上的密码（包含6位）"));
            } else {
                callback();
            }
        };

        return {
            loading: false,
            getCodeDisabled: false,
            type: 'password', //确定在登录注册忘记的页面
            // timer: null,
            getCodeText: "获取验证码",
            // getCodeDisabled1: false,

            form: {
                phone: "",
                code: "",
                password: "",
                type: '',
                tenantId: '000000',
                captchaVerification: "",
                // againPassWord: "",
                captchaType: "blockPuzzle"
            },
            rules: {
                phone: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: userName2,
                    }
                ],
                code: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: code1,
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: "blur",
                        validator: validatePass,
                    }
                ],

            }, //校验规则
            modeLogin: '密码登录'
        }
    },
    methods: {
        codeLogin() {
            this.modeLogin = '验证码登录'
            this.type = 'logincode'
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            })
        },
        passwordLogin() {
            this.modeLogin = '密码登录'
            this.type = 'password'
            this.form = {
                phone: "",
                code: "",
                password: "",
                type: 'password',
                tenantId: '000000',
            }
        },
        // 验证码登录
        CodeLogin(form) {
            let _this = this;

            this.$refs[form].validate((valid) => {
                if (valid && !this.loading) {
                    console.log(_this.form);
                    this.loading = true
                    this.form.type = this.type

                    getCodeLogin(_this.form).then((res) => {
                        if (res.code == 200) {
                            _this.$message.success({
                                showClose: true,
                                duration: 1500,
                                message: res.msg,
                                type: "success",
                                onClose: function () {
                                    _this.$store.commit("setUserInfo", res.data)
                                    _this.$store.commit("setTokenInfo", res.data.accessToken)
                                    _this.$store.commit('setIsLogin', "true")
                                    if (_this.form.rememberMe) {
                                        localStorage.setItem('username', _this.form.phone);
                                        localStorage.setItem('password', _this.form.password);
                                        localStorage.setItem('rememberMe', _this.form.rememberMe);
                                    } else {
                                        // 如果不记住密码，清除 localStorage 中的数据
                                        localStorage.removeItem('username');
                                        localStorage.removeItem('password');
                                        localStorage.removeItem('rememberMe');
                                    }
                                    _this.loading = false
                                    _this.$router.push('/')

                                },

                            });
                        } else {
                            _this.loading = false
                            _this.$message.error({
                                showClose: true,
                                duration: 1500,
                                message: res.msg
                            });
                        }
                    }).catch(err => {
                        _this.loading = false
                        _this.$message.error({
                            showClose: true,
                            duration: 1500,
                            message: err.response.data.msg || '请求失败'
                        });
                    })
                }
            });
        },
        // 密码登录
        getToLogin(form) {
            let _this = this;
            this.$refs[form].validate((valid) => {
                if (valid && !this.loading) {
                    console.log(_this.form);
                    this.loading = true
                    this.form.type = this.type

                    getCodeLogin(_this.form).then((res) => {
                        if (res.code == 200) {
                            _this.$message.success({
                                showClose: true,
                                duration: 1500,
                                message: res.msg,
                                type: "success",
                                onClose: function () {
                                    _this.$store.commit("setUserInfo", res.data)
                                    _this.$store.commit("setTokenInfo", res.data.accessToken)
                                    _this.$store.commit('setIsLogin', "true")
                                    if (_this.form.rememberMe) {
                                        localStorage.setItem('username', _this.form.phone);
                                        localStorage.setItem('password', _this.form.password);
                                        localStorage.setItem('rememberMe', _this.form.rememberMe);
                                    } else {
                                        // 如果不记住密码，清除 localStorage 中的数据
                                        localStorage.removeItem('username');
                                        localStorage.removeItem('password');
                                        localStorage.removeItem('rememberMe');
                                    }
                                    _this.loading = false
                                    _this.$router.push('/index')

                                },

                            });
                        } else {
                            _this.loading = false
                            _this.$message.error({
                                showClose: true,
                                duration: 1500,
                                message: res.msg
                            });
                        }
                    }).catch(err => {
                        _this.loading = false
                        _this.$message.error({
                            showClose: true,
                            duration: 1500,
                            message: err.response.data.msg || '请求失败'
                        });
                    })
                }
            });
        },
        // 验证码登录
        getCode() {
            const mailReg = /^1(3[0-9]|4[579]|5[0-35-9]|6[56]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
            if (this.form.phone == "") {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "请先输入手机号！",
                });
                return false
            }
            if (!mailReg.test(this.form.phone)) {
                this.$message.error({
                    showClose: true,
                    duration: 1500,
                    message: "手机号格式不正确！",
                });
                return false
            }

            // this.loading = true
            this.$refs.verify.show()
        },
        // 滑块校验通过
        success(e) {
            console.log(this.form);

            let _this = this;
            this.form.captchaVerification = e.captchaVerification
            this.form.type = this.type

            this.loading = true

            getRegisterCode(this.form).then((res => {
                if (res.code == 200) {
                    _this.$message.success({
                        showClose: true,
                        duration: 3000,
                        message: "发送成功，请注意查收",
                    });
                    this.loading = false
                    if (!_this.timer) {
                        _this.getCodeText = TIME_COUNT;
                        _this.getCodeDisabled = true;
                        _this.timer = setInterval(() => {
                            if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
                                _this.getCodeText--;
                            } else {
                                _this.getCodeDisabled = false;
                                clearInterval(_this.timer);
                                _this.getCodeText = "获取验证码";
                                _this.timer = null;
                            }
                        }, 1000);
                    }
                } else {
                    _this.$message.error({
                        showClose: true,
                        duration: 1500,
                        message: res.msg
                    });
                }
            })).finally(() => {
                this.loading = false
            })
        },
        //去忘记密码页
        forget() {
            this.$emit('type', '/forget')
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            })
        },
        // 去注册
        register() {
            this.$emit('type', '/register')
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            })
        }
    }
}
</script>

<style scoped lang="scss">
.right {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-around;

    .title_box {
        display: flex;
        justify-content: center;
        align-items: end;

        .title {
            text-align: center;
            font-size: 32px;
            font-weight: bold;
            color: #0076FE;
            padding-top: 50px;
        }

        .min_title {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            color: #0076FE;
            padding-top: 50px;
        }
    }

    .login_box {
        width: 100%;
        padding: 20px 100px;
        box-sizing: border-box;

        .form {
            width: 100%;
            margin: 0 auto;
        }

        .forget {
            text-align: end;
        }
    }
}
</style>