<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        代开检索&ESI高被引<span>证明</span>
                    </div>
                    <div class="center">

                        <div class="describe">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                            检索证明
                        </div>
                        <div class="describe">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                            高被引证明
                        </div>


                        <div class="describe">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                            热点证明
                        </div>

                    </div>
                    <div class="btn">
                        <el-button class="top_btn" @click="openRandomDialog">立即检索</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/prove_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="title_box">
                <div class="title">
                    WOS检索流程
                </div>
                <div class="text">
                    全球超330万学者使用的检索平台
                </div>
            </div>
            <div class="science">
                <div class="item">
                    <div class="img">
                        <img src="@/assets/image/science_1.png" alt="">
                    </div>
                    <div class="info">
                        <div class="info_top">
                            登陆Web of Science
                        </div>
                        <div class="sort">
                            01
                        </div>
                    </div>
                </div>
                <div class="item">

                    <div class="info">
                        <div class="sort">
                            02
                        </div>
                        <div class="info_top">
                            选择主题/标题/DOI/Wos号（入藏号）
                        </div>

                    </div>
                    <div class="img">
                        <img src="@/assets/image/science_2.png" alt="">
                    </div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="@/assets/image/science_3.png" alt="">
                    </div>
                    <div class="info">
                        <div class="info_top">
                            输入检索内容，进行检索
                        </div>
                        <div class="sort">
                            03
                        </div>
                    </div>
                </div>
            </div>
            <div class="paper">
                <div class="left">
                    <div class="paper_top">
                        <div class="title">
                            高被引论文
                            <div class="wire"></div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/image/science_icon_2.png" alt="">
                        </div>
                    </div>
                    <div class="content">
                        高被引论文是指近十年SCI，SSCI论文在同年份同学科中被引在前1%的论文。
                    </div>
                </div>
                <div class="right">
                    <div class="paper_top">
                        <div class="title">
                            热点论文
                            <div class="wire"></div>
                        </div>
                        <div class="icon">
                            <img src="@/assets/image/science_icon_1.png" alt="">
                        </div>
                    </div>
                    <div class="content">
                        热点论文是指最近两年SCI，SSCI论文在近两个月中被引在前1‰的论文。
                    </div>
                </div>
            </div>
            <div class="title_box">
                <div class="title">
                    代开单位及报价说明
                </div>
                <div class="text">
                    当前页面展示代开证明单位价格，如果想查看相应证明模版内容，请联系客服获取
                </div>
            </div>
            <div class="costList">
                <div class="item  pointer" v-for="(item, index) in list" :key="index" @click="getItem(index)"
                    :class="{ active: index == active }">
                    <div class="title" v-if="item.title != '中国科学院武汉科技查新咨询检索中心'">
                        <p>
                            {{ item.title }}
                        </p>
                    </div>
                    <div class="title" v-else>
                        <p style="margin: 10px 0 0 0;">中国科学院</p>
                        <p style="margin: 0;">武汉科技查新咨询检索中心</p>
                    </div>
                    <div class="btn_box">
                        <div>
                            <div class="btn_left" @click="getActive(index, 0)"
                                :class="{ btn_right: item.active === 0 }">
                                检索证明
                            </div>
                            <div class="btn_left" @click="getActive(index, 1)"
                                :class="{ btn_right: item.active === 1 }">
                                高被引/热点证明
                            </div>
                        </div>
                    </div>
                    <div class="money">
                        <span class="money_icon">¥</span> {{ item.money[item.active] }} <span class="unit">/篇/份</span>
                    </div>
                    <ul>
                        <li v-for="(items, indexs) in item.infoList[item.active]" :key="indexs"
                            :style="{ visibility: items === 'none' ? 'hidden' : 'visible' }">
                            {{ items }}
                        </li>
                    </ul>

                    <div class="el_btnbox">
                        <el-button class="el_btn" @click="() => dialogVisible0 = true">立即检索</el-button>
                    </div>
                </div>
            </div>
        </main>
        <journal-recommend></journal-recommend>
        <div class="bottom_box">
            <div class="section">
                <div class="left">
                    <div class="title">
                        高被引论文引用追踪报告

                    </div>
                    <div class="text">
                        ESI- Highly Cited Paper Citation Tracking & Prediction
                    </div>
                    <el-button plain class="left_btn">免费获取</el-button>
                </div>
                <div class="right">
                    <img src="@/assets/image/report_bg.png" alt="">
                </div>
            </div>
        </div>

        <dialogQR :dialogVisible="dialogVisible0" :path="$route.path" :index="1" @cancel="() => dialogVisible0 = false"
            @confirm="() => dialogVisible0 = false">
        </dialogQR>
        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="0" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import randomQR from "@/components/common/randomQR.vue";
import dialogQR from "@/components/common/dialogQR.vue";

export default {
    components: {
        journalRecommend,
        randomQR,
        dialogQR
    },
    data() {
        return {
            dialogVisible0: false,
            dialogVisible: false,
            activeName: 'first',
            active: 0,
            list: [
                {
                    title: '中国科学院武汉科技查新咨询检索中心',
                    active: 0,
                    precept: ["检索证明", '高被引/热点证明'],
                    money: [100, 100],
                    infoList: [
                        [
                            '加影响因子/分区+20元',
                            '纸质版/电子版二选一',
                            '顺丰包邮'
                        ],
                        [
                            '无影响因子/分区',
                            '纸质版/电子版二选一',
                            '顺丰包邮'
                        ],
                    ]
                },
                {
                    title: '浙江工业大学图书馆',
                    active: 0,
                    precept: ["检索证明", '高被引/热点证明'],
                    money: [75, 75],
                    infoList: [
                        [
                            '加影响因子/分区+20元',
                            '纸质版',
                            '顺丰到付'
                        ],
                        [
                            '无影响因子/分区',
                            '纸质版',
                            '顺丰到付'
                        ],
                    ]
                },
                {
                    title: '湖南大学图书馆',
                    active: 0,
                    precept: ["检索证明", '高被引/热点证明'],
                    money: [75, 75],
                    infoList: [
                        [
                            '加影响因子/分区+20元',
                            '电子版',
                            'none'
                        ],
                        [
                            '无影响因子/分区',
                            '电子版',
                            "none"
                        ],
                    ]
                }
            ]
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        getActive(index, num) {

            this.list[index].active = num
        },
        getItem(index) {

            this.active = index
        },
        openRandomDialog() {
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 420px;
        height: 423px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;


            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;
            display: flex;

            >div {
                margin-right: 10px;
            }

            .describe {
                margin: 12px 12px;
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #fff;

                .icon {
                    // width: 18px;
                    // height: 18px;
                    padding: 3px;
                    display: block;
                    border-radius: 50%;
                    background-color: #2FC54F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    i {
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    margin: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            color: #3470ff;
            font-size: 32px;
            font-weight: bold;

        }

        .text {
            font-style: italic;
            color: #434343;
            font-size: 18px;
        }
    }

    .science {
        display: flex;
        padding: 20px;
        margin: 50px 0;

        .item {
            width: calc(calc(100% - 30px) /3);
            margin: 4px;

            .img {
                // width: 400px;
                width: 100%;
                height: 245px;
                transition: transform 0.3s ease;
                /* 添加变换动画 */
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    transition: transform 0.3s ease;
                    /* 添加变换动画 */
                }
            }

            .img:hover img {
                transform: scale(1.2);
                /* 鼠标悬停时放大 */
            }

            .info {
                display: flex;
                background-color: #3470ff;
                flex-direction: column;
                padding: 20px;

                .info_top {
                    font-size: 14px;
                    color: #fff;
                }

                .sort {
                    font-size: 40px;
                    color: #fff;
                    font-weight: bold;
                }
            }

        }
    }

    .paper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;

        >div {
            width: calc(100% / 2);
        }

        .left {
            background-color: #434343;
            padding: 20px;
            color: #fff;
        }

        .right {
            background-image: linear-gradient(0deg,
                    #5d3bdd 0%,
                    #07367e 100%),
                linear-gradient(#434343,
                    #434343);
            background-blend-mode: lighten,
                normal;
            padding: 20px;
            color: #c5d3f8;
        }

        .paper_top {

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;

            .title {
                font-size: 30px;
                font-weight: bold;

                .wire {
                    // width: 394px;
                    height: 2px;

                    background-image: linear-gradient(90deg,
                            #3470ff 0%,
                            #c5d3f8 100%),
                        linear-gradient(#ffffff,
                            #ffffff);
                    background-blend-mode: normal,
                        normal;
                    ;
                }
            }

            .icon {
                width: 90px;
                height: 110px
            }
        }

        .content {
            padding: 30px;
        }
    }

    .costList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 50px;

        >div {
            width: calc(calc(100% - 100px) /3);
            border: solid 1px #eeeeee;
            margin: 10px;

        }

        .item {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            // align-items: flex-start;
            border-radius: 10px;
            transition: all .3s;

            .title {
                width: 100%;
                height: 60px;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 54px
            }

            .btn_box {

                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                >div {
                    display: flex;
                    align-items: center;

                    border: solid 1px #3470ff;
                    border-radius: 5px;
                    text-wrap: nowrap;
                    font-size: 12px;
                    text-align: center;

                    >div {
                        width: 80px;
                        padding: 5px 20px;
                    }
                }

                .btn_right {
                    background-color: #3470ff;
                    color: #fff;
                }

            }

            .money {
                font-size: 36px;
                color: #3470ff;
                padding: 20px;
                font-weight: bold;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: baseline;
                box-sizing: border-box;

                .money_icon {
                    font-weight: normal;
                    font-size: 24px;
                    color: #434343;
                }

                .unit {
                    font-weight: normal;
                    font-size: 18px;
                    color: #434343;
                }
            }

            ul {
                margin-left: 40px;
                box-sizing: border-box;
                padding: 20px 40px;
                color: #434343;
                font-size: 14px;
            }

            .el_btnbox {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;

                .el_btn {
                    width: 100%;
                    padding: 20px;
                    color: #3470ff;
                    /*  background-color: #3470ff;
                    border: #3470ff; */
                }
            }
        }

        .active {
            border: 1px solid #3470ff;

            .title {
                color: #3470ff;
            }

            .el_btn {
                background-color: #3470ff;
                border: #3470ff;
                color: #fff !important;
            }
        }

    }
}

.bottom_box {
    width: 100%;
    background-image: linear-gradient(-90deg,
            #3470ff 0%,
            #0d42c2 100%),
        linear-gradient(#3470ff,
            #3470ff);
    background-blend-mode: normal,
        normal;

    .section {
        width: 1200px;
        margin: 0 auto;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .title {
                font-size: 44px;
                color: #fff;
                font-weight: bold;
                // padding: 20px;
            }

            .text {
                padding-bottom: 20px;
                font-size: 16px;
                color: #c5d3f8;
            }

            .left_btn {
                width: 156px;
                height: 42px;
            }
        }

        .right {

            width: 280px;
            height: 345px;

            img {
                width: 100%;
                height: 100%;

            }
        }
    }
}
</style>
<style scoped></style>