<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        关于我们
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/user_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="intro_box">
                <div class="intro_img">
                    <img src="@/assets/image/company.jpg" alt="">
                </div>
                <div class="content">
                    <div class="intro_title">
                        <div class="icon">
                            <img src="@/assets/image/11_07.png" alt="">
                        </div>
                        <div>
                            关于我们
                        </div>
                        <div class="po_ab">
                            ABOUT US
                        </div>
                    </div>
                    <div class="text">
                        <div>
                            深圳睿金易美科技服务有限公司由一批具有创业精神和激情的学术精英创立，公司核心团队成员均来自国内一流高校，部分具有国外名校留学深造经历，具有深厚的学术背景和经验。
                        </div>
                        <div>
                            睿金易美始终坚持用户第一、质量至上的理念，通过一流的能力和人才，为科研工作者提供专业的、多元化的学术咨询服务，致力于提高国内科研论文以及期刊的国际影响力，加强高校学科建设。
                        </div>
                    </div>
                </div>
            </div>
            <div class="introduce">
                <div>
                    深圳睿金易美科技服务有限公司成立于2020年，由留美博士和归国学子联合创立。睿金易美作为一家学术传播的国际性公司，专注于学术推广服务，基于高质量的数据和专业的服务，帮助高校、研究机构、学术团体、企业、政府以及个人提高学术和专业影响力；“全球学术影响力提升服务”以更多、更快、更好、更省地方式将学术品牌、学术成果、合作项目以及国际会议等信息及时有效的推广至全球学术圈。
                </div>
                <div>
                    公司旗下有睿金留学、箭弩学术、易美编校、科乐网、郑州龙华全科教育科技有限公司和高被引APP，为全国科研工作者提供专业服务。
                </div>
                <div class="left_top top"></div>
                <div class="top_left top"></div>
                <div class="left_top bottom"></div>
                <div class="top_left bottom"></div>
            </div>
            <div class="section">
                <div class="title_box">
                    <div class="po_ab">
                        CORPORATE CULTURE
                    </div>
                    <div class="title">
                        企业文化
                    </div>
                </div>
                <div class="culture">
                    <div :class="cultureCenterActiveIndex === item.id ? 'culture_box culture_center' : 'culture_box'"
                        v-for="(item, index) in cultureList" :key="item.id" @mouseover="activateCultureCenter(item.id)">
                        <div class='culture_title'>
                            <img :src="cultureCenterActiveIndex === item.id ? item.moveIcon : item.icon" alt="">
                            <div class="" style="margin-top: 14px;">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="line">

                        </div>
                        <div class="text">
                            {{ item.desc }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="title_box">
                    <div class="po_ab">
                        CONTACT US
                    </div>
                    <div class="title">
                        联系我们
                    </div>
                </div>
                <div class="relation">
                    <div class="relation_box">
                        <div class="icon">
                            <img src="@/assets/image/location_icon.png" alt="">
                        </div>
                        <div class="relation_title_box">
                            <div class="title">
                                公司地址
                            </div>
                            <div class="content size_width">
                                深圳市南山区桃源街道平山社区留仙大道4168号众冠时代广场A座1515A
                            </div>
                        </div>
                    </div>
                    <div class="relation_box">
                        <div class="icon">
                            <img src="@/assets/image/phone_icon.png" alt="">
                        </div>
                        <div class="relation_title_box">
                            <div class="title">
                                联系电话
                            </div>
                            <div class="content">
                                199-2454-7910
                            </div>
                        </div>
                    </div>
                    <div class="relation_box">
                        <div class="icon">
                            <img src="@/assets/image/email_icon.png" alt="">
                        </div>
                        <div class="relation_title_box">
                            <div class="title">
                                联系邮箱
                            </div>
                            <div class="content">
                                service@highlycitedpaper.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import img1 from "@/assets/image/22_03.png"
import img2 from "@/assets/image/22_05.png"
import img3 from "@/assets/image/22_07.png"
import img4 from "@/assets/image/11_03.png"
import img5 from "@/assets/image/11_05.png"
import img6 from "@/assets/image/11_07_1.png"

export default {
    data() {
        return {
            activeName: 'first',
            cultureCenterActiveIndex: 1,
            cultureList: [
                {
                    id: 0,
                    icon: img1,
                    moveIcon: img4,
                    name: "我们的使命",
                    desc: "服务客户，诚信共享"
                },
                {
                    id: 1,
                    icon: img2,
                    moveIcon: img5,
                    name: "我们的愿景",
                    desc: "为读者找文章，为文章找读者"
                },
                {
                    id: 2,
                    icon: img3,
                    moveIcon: img6,
                    name: "我们的价值观",
                    desc: "为学者提供科研成果推广平台，创造新的学术分享机制，提升学者的社会影响力"
                }
            ]
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        activateCultureCenter(index) {
            this.cultureCenterActiveIndex = index
        },
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #121E2C;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 998px;
        height: 490px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;

        }

    }
}

main {
    width: 1200px;
    margin: 50px auto;
    box-sizing: border-box;

    .po_ab {
        position: absolute;
        color: #aeaeae;
        opacity: 0.2;
        font-style: italic;
        font-size: 32px;

        font-weight: bold;
    }

    .intro_box {
        display: flex;
        justify-content: space-between;

        .intro_img {
            width: 558px;
            height: 391px;
            background-color: #c5d3f8;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .content {
            width: 597px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;

            .intro_title {
                display: flex;
                align-items: center;
                color: #3470ff;
                font-size: 36px;

                .icon {
                    margin-right: 10px;
                }

                .po_ab {
                    margin-top: -20px;
                    margin-left: 90px;
                }
            }

            .text {
                flex-grow: 1;
                padding: 20px;
                color: #434343;
                line-height: 32px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }

        }
    }

    .introduce {
        position: relative;
        margin: 50px 0;
        color: #fff;
        padding: 30px;
        background-color: #434343;
        line-height: 30px;

        .left_top {

            width: 200px;
            height: 5px;
            background-color: #3470ff;

        }

        .top_left {
            width: 5px;
            height: 98px;
            background-color: #3470ff;

        }

        .top {
            position: absolute;
            left: 0;
            top: 0;
        }

        .bottom {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .section {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title_box {
            display: flex;
            justify-content: center;
            font-size: 36px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 26px;
            letter-spacing: 0px;
            color: #3470ff;

            .po_ab {
                margin-top: -20px;
            }
        }

        .culture {
            width: 100%;
            height: 326px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            margin: 50px 0;
            position: relative;


            .culture_box {
                width: 386px;
                height: 292px;
                background-color: #ffffff;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 40px;
                box-sizing: border-box;
                justify-content: space-evenly;
                box-shadow: 0 0 5px 5px rgba(147, 138, 138, 0.2);
                transition: all .3s;

                .culture_title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 18px;

                    .iconfont {
                        padding-bottom: 20px;
                        font-size: 54px;
                    }
                }

                .line {
                    width: 30px;
                    height: 4px;
                    background-color: #3470ff;
                }

                .text {
                    color: #666;
                    font-size: 14px;
                    padding: 0 20px;
                }
            }

            .culture_center {
                width: 464px;
                height: 326px;
                background-color: #3470ff;

                .culture_title {
                    color: white;
                }

                .white {
                    color: #fff;
                }

                .line {
                    background-color: #fff;
                }

                .text {
                    color: white;
                }
            }
        }

    }

    .relation {
        display: flex;
        width: 100%;
        padding: 20px;
        margin: 50px 0;
        justify-content: space-between;

        .relation_box {
            // width: calc(calc(100% - 100px)/3);
            display: flex;

            .icon {
                width: 94px;
                height: 94px;
                background-color: #3470ff;
                // padding-right: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 48px;
                color: #fff;
                margin-right: 10px;

                img {
                    width: 44px;
                    // height: 54px;
                }
            }

            .relation_title_box {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .title {
                    color: #434343;
                    font-size: 18px;
                }

                .content {
                    color: #666666;
                    font-size: 14px
                }

                .size_width {
                    width: 242px;
                }
            }
        }
    }
}
</style>
<style scoped></style>