<template>
    <div class="add_box" :class="placeClass">
        <div class="QR_img">
            <imageList :imageType="imageType"></imageList>
        </div>
        <div class="icon">
            <span class="iconfont icon-weixin" style="color: #549E51;"></span>
        </div>
        <div class="title">
            扫码添加客服
        </div>
        <div class="text">
            获取分析报告
        </div>
    </div>
</template>
<script>
import imageList from '@/components/common/imageList.vue'
export default {
    components: {
        imageList
    },
    props: {
        place: '',
        imageType: {
            type: Number,
            required: true
        }
    },
    computed: {
        placeClass() {
            return {
                top: this.place == 'top',
                center1: this.place == 'center1',
                bottom1: this.place == 'bottom1'
            }
        }
    }
};
</script>
<style scoped lang="scss">
.add_box {
    /*  width: 270px;
    height: 438px; */
    background-color: #c5d3f8;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;

    .QR_img {
        width: 156px;
        height: 156px;
        background-color: #fff;
    }

    .icon {
        padding: 20px;

        span {
            font-size: 52px;
        }
    }

    .title {
        color: #434343;
        font-size: 18px;
    }

    .text {
        color: #666666;
        font-size: 16px;
    }
}

.top {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
}

.center1 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    border-radius: 10px 0px 0px 10px;
}

.bottom1 {
    position: absolute;
    right: 0;
    bottom: 50px;
    transform: translateX(100%);
}
</style>