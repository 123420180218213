<template>
	<div class="box">
		<!-- 头部图片 -->
		<div class="top_img">
			<!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
			<div class="top_box">
				<div class="title">
					为读者找文章，为文章找读者
				</div>
				<div class="describe">
					<div class="icon">
						<i class="el-icon-check"></i>
					</div>
					实时提供SCI论文入选高被引论文差距的检测服务

				</div>
				<div class="describe">
					<div class="icon">
						<i class="el-icon-check"></i>
					</div>
					为学者提供一对一专业指导，帮助学者扩大学术影响力
				</div>
				<div class="describe">
					<div class="icon">
						<i class="el-icon-check"></i>
					</div>
					通过大数据分析技术，帮助学者高效管理学术成果
				</div>
			</div>

			<div class="down_right">
				<div class="QR_box pointer" @click="downloadExternalFile" title="点击下载">
					<span class="iconfont icon-anzhuo"></span>
					<div class="down_r_name">
						Android 下载
					</div>
				</div>
				<div class="QR_box pointer">
					<span class="iconfont icon-pingguo" style="color: #000;"></span>
					<div class="down_r_name">
						敬请期待
					</div>
				</div>
			</div>
		</div>
		<product-service></product-service>
		<!-- <div class="piece_box">
			<div class="title_box">
				<div class="title">
					产品服务介绍
				</div>
				<div class="text">
					利用大数据，收集、分析，论文被引情况，把您的论文精准推送给大量可能引用的研究者，
					从而提升您的个人学术数据，提升学术影响力
				</div>
			</div>
			<div class="product">
				<div class="product_item">
					<div class="product_title">
						论文影响力推广
					</div>
					<div class="content">
						通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升您的个人学术数据，提升学术影响力。
					</div>
				</div>
				<div class="product_item">
					<div class="product_title">
						个人影响力推广
					</div>
					<div class="content">
						<ul>
							<li>
								H指数推广
							</li>
							<li>
								全球高被引科学家培育
							</li>
							<li>
								中国高被引学者培育
							</li>
							<li>
								斯坦福顶尖2%科学家培育
							</li>

						</ul>
					</div>
				</div>
				<div class="product_item">
					<div class="product_title">
						期刊影响力推广
					</div>
					<div class="content">
						<ul>
							<li>
								期刊运营指导，帮助新创期刊进行管理，代运营
							</li>
							<li>
								帮助期刊进行征稿宣传
							</li>
							<li>
								为期刊招募主编、客编等
							</li>
							<li>
								期刊影响因子管理，提升期刊影响力"
							</li>

						</ul>
					</div>
				</div>
				<div class="product_item">
					<div class="product_title">
						高校学科建设咨询
					</div>
					<div class="content">
						<ul>
							<li>
								了解本校学科与设定目标、对标高校的具体差距
							</li>
							<li>
								分析生成学科报告，深度总结学科发展
							</li>
							<li>
								制定建设方案
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div> -->
		<div class="piece_box">
			<div class="title_box">
				<div class="title">
					核心功能介绍
				</div>
				<div class="text">
					基于我国学术科研的高质量发展，高被引应运而生，
					完美解决了机构和学者的科研需求
				</div>
			</div>
			<div class="features">
				<div class="features_item">
					<div class="features_top">
						<span class="iconfont icon-yishuceping_cepingguanli" style="font-size: 20px;"></span>
						论文差距测评
					</div>
					<div class="content">
						免费为您提供Highly Cited 测评查询，方便快捷计算距离高被引的引用次数。
					</div>
				</div>
				<div class="features_item">
					<div class="features_top">
						<span class="iconfont icon-zizhutuiguang" style="font-size: 20px;"></span>
						成果推广
					</div>
					<div class="content">
						通过大数据分析，把您的论文精准推送给大量可能引用的研究者，从而提升您的个人学术数据，提升学术影响力。
					</div>
				</div>
				<div class="features_item">
					<div class="features_top">
						<span class="iconfont icon-wodezhengming" style="font-size: 20px;"></span>
						代开证明
					</div>
					<div class="content">
						代开检索和ESI高被引证明
					</div>
				</div>
				<div class="features_item">
					<div class="features_top">
						<span class="iconfont icon-icon__fengxianceping" style="font-size: 20px;"></span>
						引文追踪报告
					</div>
					<div class="content">
						为您提供深入的引文分析，帮助您了解您的研究在学术界的表现，并为您的未来研究提供战略性建议。
					</div>
				</div>
			</div>
			<!-- <div class="features2 features">
				<div class="features_item features_item2">
					<div class="features_top">
						<i class="el-icon-s-order" style="font-size: 20px;"></i>
						高被引论文差距测评
					</div>
					<div class="content">
						免费为您提供Highly Cited 测评查询，方便快捷计算距离高被引的引用次数。
					</div>
				</div>
				<div class="features_item features_item2">
					<div class="features_top">
						<i class="el-icon-s-order" style="font-size: 20px;"></i>
						经典文献
					</div>
					<div class="content">
						高水平论文经典存档，为学者提供历史经典文献。
					</div>
				</div>
				<div class="features_item features_item2">
					<div class="features_top">
						<i class="el-icon-s-order" style="font-size: 20px;"></i>
						必参文献
					</div>
					<div class="content">
						科学研究趋势，最近的热门研究前沿，要读什么文献？为什么读这些文献？这些不读读什么？保持自己对学科前沿的动态在线？
					</div>
				</div>
			</div> -->
		</div>
		<div class="quote">
			<div class="quote_box">
				<div class="quote_title">
					论文引用差距测量中心
				</div>
				<div class="content">
					根据Google Scholar数据库精确计算，入选知名数据库收录为高被引论文
				</div>
				<div class="quote_btn">
					<el-button plain class="btn" @click="goToAppraisal">开始测评</el-button>
				</div>
			</div>
		</div>
		<journal-recommend></journal-recommend>
		<!-- <div class="issue">
			<div class="issue_title">
				常见问题
			</div>
			<div class="text">
				如果您对高被引APP的操作使用有任何问题，请通过邮箱
				<span style="color: #3470ff;">
					services@highlycitedpaper.com
				</span>
				联系我们进行反馈
			</div>
			<div class="collapse">
				<el-collapse accordion>
					<el-collapse-item>
						<template slot="title">
							<span>
								苹果手机可以下载使用吗？
							</span>
						</template>
<div>
	目前仅支持安卓手机系统及鸿蒙OS系统下载使用，IOS系统待开发上线，敬请期待。
</div>
</el-collapse-item>
<el-collapse-item>
	<template slot="title">
							<span>
								高被引APP是一款什么工具？能做什么？
							</span>
						</template>
	<div>
		目前仅支持安卓手机系统及鸿蒙OS系统下载使用，IOS系统待开发上线，敬请期待。
	</div>
</el-collapse-item>
<el-collapse-item>
	<template slot="title">
							<span>
								APP下载免费吗？
							</span>
						</template>
	<div>
		目前仅支持安卓手机系统及鸿蒙OS系统下载使用，IOS系统待开发上线，敬请期待。
	</div>
</el-collapse-item>
</el-collapse>
</div>
</div> -->
		<!-- <div class="swwiper_box">
			<mySwiper></mySwiper>
		</div> -->

	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
// import mySwiper from "./components/swiper.vue"   
import journalRecommend from "@/components/common/journalRecommend.vue"
import productService from "@/components/common/productService.vue"
export default {
	components: {
		journalRecommend,
		productService
	},
	name: 'index',
	computed: {
		...mapState(['headerActive'])
	},
	data() {
		return {
			activeNames: ['1']
		}
	},
	created() {

	},
	mounted() {


	},
	methods: {
		...mapMutations(['setSharedData']),
		handleChange(val) {
			console.log(val);
		},
		downloadExternalFile() {
			window.location.href = 'http://share.mostcitedpaper.com/downloadLocal?fileName=HighlyCited.apk';

		},
		goToAppraisal() {
			this.$router.push('/appraisal')
			this.setSharedData('/appraisal');

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
}
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;
	background-color: hsl(240, 33.33%, 98.82%);
	box-sizing: border-box;
}

// 头部图片
.top_img {
	width: 100%;
	height: 750px;
	background-image: url('@/assets/image/top_img.jpg');
	background-size: cover;
	/* 让背景图片铺满整个元素 */
	background-position: center;
	/* 图片居中对齐 */
	background-repeat: no-repeat;
	box-sizing: border-box;

	/* 防止图片重复 */
	>img {
		position: absolute;
		height: 750px;
		width: 100%;
	}

	.top_box {
		position: relative;
		z-index: 99;
		width: 1200px;
		margin: 0 auto;
		padding-top: 100px;

		.title {
			font-size: 50px;
			font-weight: 600;
			font-style: italic;
			margin-bottom: 40px;

		}

		.describe {
			margin: 12px 0;
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #434343;

			.icon {
				// width: 18px;
				// height: 18px;
				padding: 3px;
				display: block;
				border-radius: 50%;
				background-color: #2FC54F;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 16px;

				i {
					font-size: 12px;
					color: #fff;
				}
			}
		}
	}

	.down_right {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-top: 100px;

		.QR_box {
			margin: 0 10px;
			width: 120px;
			height: 120px;
			// border: 1px solid #000;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			line-height: 40px;

			span {
				font-size: 38px;
				// color: #fff;
			}

			.down_r_name {
				font-size: 14px;
				color: #666;
			}

		}

		.QR_box:nth-of-type(1)>span {
			color: #08C873;
		}

		.QR_box:nth-of-type(2)>span {
			color: #549E51;
		}
	}
}

.piece_box {
	width: 100%;
	margin: 0 auto;
	// padding: 40px;
	box-sizing: border-box;

	.title_box {
		padding: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;


		.title {
			color: #3470ff;
			font-size: 32px;
			font-weight: bold;
			// line-height: 50px;
			padding-bottom: 20px;
		}

		.text {
			color: #434343;
			font-style: italic;
			font-size: 18px;
			width: 600px;
			text-align: center;
		}
	}
}

// // 产品介绍
// .product {
// 	display: flex;
// 	// align-items: center;
// 	max-width: 1623px;
// 	justify-content: center;
// 	margin: 0 auto;

// 	.product_item {
// 		position: relative;
// 		/* 确保伪元素可以定位 */
// 		width: calc(calc(100% - 600px) /4);
// 		// width: 200px;
// 		min-height: 275px;
// 		// height: 100%;
// 		padding: 20px;
// 		margin: 17px;
// 		background-image: url('@/assets/image/product_bg.png');
// 		// background-color: #000;
// 		background-size: cover;
// 		/* 让背景图片铺满整个元素 */
// 		background-position: center;
// 		/* 图片居中对齐 */
// 		background-repeat: no-repeat;

// 		.product_title {
// 			position: relative;
// 			font-size: 28px;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			// margin-bottom: 30px;
// 			color: #fff;
// 			z-index: 999;
// 			padding: 20px;
// 			text-wrap: nowrap;
// 		}

// 		.content {
// 			position: relative;
// 			z-index: 999;
// 			color: #fff;

// 			ul {
// 				line-height: 32px;
// 				padding: 0 20px;
// 			}
// 		}
// 	}

// 	/* 奇数项（1, 3, 5, ...）添加上边框 */
// 	.product_item:nth-child(odd) {
// 		border-bottom: 5px solid #3470FF;
// 		/* 添加上边框，蓝色，宽度为3px */
// 	}

// 	/* 偶数项（2, 4, 6, ...）添加下边框 */
// 	.product_item:nth-child(even) {
// 		border-top: 5px solid #3470FF;
// 		/* 添加下边框，蓝色，宽度为3px */
// 	}

// 	.product_item::before {
// 		content: '';
// 		position: absolute;
// 		top: 0;
// 		bottom: 0;
// 		left: 0;
// 		right: 0;
// 		background-color: rgba(67, 67, 67, 0.5);
// 		/* 半透明的颜色叠加层 */
// 		// filter: blur(5px);
// 		/* 模糊效果 */
// 		z-index: 1;
// 		/* 确保在背景图片之上 */
// 		transition: all 0.5s;
// 	}

// 	.product_item:hover::before {
// 		background-color: rgba(73, 73, 160, 0.5);
// 		/* 鼠标移入时的颜色 */
// 	}
// }

// 产品特点
.features {
	width: 100%;
	// height: 558px;
	background-image: url('@/assets/image/features_bg1.jpg');
	background-size: cover;
	/* 让背景图片铺满整个元素 */
	background-position: center;
	/* 图片居中对齐 */
	background-repeat: no-repeat;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;

	.features_item {
		// margin: 10px;
		// margin-left: 50%;
		transform: translateX(100%);
		width: 300px;
		display: flex;
		flex-direction: column;
		padding: 20px;
		transition: all 0.3s;

		.features_top {
			font-size: 20px;
			font-weight: 500;
		}

		.content {
			font-size: 14px;
			padding-top: 20px;
		}
	}

	.features_item:hover {
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

		.features_top {
			color: #3470FF;
		}
	}

}

/* .features2 {
	background-image: url('@/assets/image/features2_bg.jpg');
	// justify-content: end;

	.features_item2 {
		margin-left: 20%;
	}
} */

// 引用测量中心
.quote {
	width: 100%;
	// height: 370px;
	background-image: url('@/assets/image/quote_bg.jpg');
	background-size: cover;
	/* 让背景图片铺满整个元素 */
	background-position: center;
	/* 图片居中对齐 */
	background-repeat: no-repeat;
	box-sizing: border-box;
	margin-top: 100px;

	.quote_box {
		width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 25%;
		padding: 107px 0;

		.quote_title {
			font-size: 32px;
			color: #fff;
			font-weight: bold;
			padding: 20px;

		}

		.content {
			color: #c5d3f8;
		}

		.quote_btn {
			padding: 20px;
			display: flex;
		}

		.btn {
			width: 156px;
			height: 42px;
			font-size: 14px;
		}
	}


}

// 常见问题
.issue {
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 1000px;
	margin: 0 auto;

	.issue_title {
		padding: 20px;
		font-size: 32px;
		font-weight: 500;
		color: #3470ff;
	}

	.text {
		font-style: italic;

	}

	.collapse {
		width: 100%;
	}
}

.swwiper_box {
	width: 1000px;
	margin: 0 auto;
}
</style>
<style scoped>
::v-deep .issue .el-collapse-item__header,
::v-deep .issue .el-collapse-item__wrap {
	background-color: rgba(0, 0, 0, 0);
}

::v-deep .issue .el-collapse-item__header {
	color: #3470ff;
	font-size: 16px;
}

::v-deep .issue .el-collapse {
	border: none;
}

::v-deep .issue .el-collapse-item {
	padding: 20px 0;
}
</style>
