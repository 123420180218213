<template>

</template>
<script>

</script><template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        期刊影响力<span>推广</span>
                    </div>
                    <div class="center">
                        <div class="describe">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                            期刊运营指导
                        </div>
                        <div class="describe">
                            <div class="icon">
                                <i class="el-icon-check"></i>
                            </div>
                            期刊影响力提升
                        </div>
                    </div>
                    <div class="btn">
                        <el-button class="top_btn" @click="openRandomDialog">开始推广</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/journalPromotion_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="section">
                <div class="section_top">
                    <div class="left">
                        <div class="radius_box">
                            期刊运营指导，帮助新创期刊进行管理，代运营
                        </div>
                    </div>
                    <div class="end">
                        <div class="radius_box" style="transform: translateY(-11%)">
                            给期刊招募主编、客编等
                        </div>
                    </div>
                </div>
                <div class="section_top">
                    <div class="left">
                        <div class="radius_box">
                            帮助期刊进行征稿宣传
                        </div>
                    </div>
                    <div class="end">
                        <div class="radius_box" style="transform: translateY(11%)">
                            期刊影响因子管理，提升期刊影响力
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <journal-recommend></journal-recommend>
        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="1" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import dialogQR from "@/components/common/dialogQR.vue";

export default {
    components: {
        journalRecommend,
        dialogQR
    },
    data() {
        return {
            dialogVisible: false,
            activeName: 'first'
        }
    },
    created() {

    },
    mounted() {


    },
    methods: {
        openRandomDialog() {
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 487px;
        height: 431px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;

            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;

            .describe {
                margin: 12px 0;
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #fff;

                .icon {
                    // width: 18px;
                    // height: 18px;
                    padding: 3px;
                    display: block;
                    border-radius: 50%;
                    background-color: #2FC54F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    i {
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    margin: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .section {
        width: 100%;
        height: 713px;
        background-image: url('@/assets/image/journal_bg (2).png');
        // background-size: cover;
        /* 让背景图片铺满整个元素 */
        background-position: center;
        /* 图片居中对齐 */
        background-repeat: no-repeat;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 61px 166px 23px 150px;

        .radius_box {
            width: auto;
            padding: 25px;
            background-color: #fbfeff;
            border-radius: 36px;
            display: flex;
            // align-items: center;
            box-shadow: 0 0 5px 5px rgba(147, 138, 138, 0.2);
            // justify-content: left;
        }

        .end {
            display: flex;
            align-items: center;
            justify-content: end;
        }

        .left {
            display: flex;
            align-items: center;
            justify-content: left;
        }
    }

}
</style>
<style scoped></style>