import request from '@/utils/request';

/* export const login = (form) => {
  return request({
    url: '/joi/login',
    method: 'post',
    data: form
  })
} */
export const login = (form) => {
  return request({
    url: '/joi/login',
    method: 'post',
    data: form
  })
}

export const loginOut = () => {
  return request({
    url: '/joi/loginOut',
    method: 'post',
    data: ''
  })
}

export const reset = (form) => {
  return request({
    url: '/joi/resetPasswordByPhone',
    method: 'post',
    data: form
  })
}
/* export const getRegister = (form) => {
  return request({
    url: '/joi/registerByPhone',
    method: 'post',
    data: form
  })
} */

export const getVerificationCode = (form) => {
  return request({
    url: '/joi/sendVerificationCodeByPhone',
    method: 'post',
    data: form
  })
}
// 获取验证码
export const getLoginCode = (form) => {
  return request({
    url: '/joi/sendVerificationCode',
    method: 'post',
    data: form
  })
}
/* //注册
export const getRegister = (form) => {
  return request({
    url: '/joi/register',
    method: 'post',
    data: form
  })
} */
// 重置密码
export const getReset = (form) => {
  return request({
    url: '/url/blade-auth/retrieve/password',
    method: "get",
    params: form
  })
}
// 登录
/* export const getLogin = (form) => {
  return request({
    url: '/joi/login',
    method: "post",
    data: form
  })
} */

// new  登录
export const getLogin = (form) => {
  return request({
    url: '/url/blade-auth/token',
    method: "post",
    params: form
  })
}

// 统一登录(验证码/密码)
export const getCodeLogin = (form) => {
  return request({
    url: '/url/blade-auth/user/login',
    headers: {
      Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
    },
    method: "post",
    params: form
  })
}

//注册
export const getRegister1 = (form) => {
  return request({
    url: '/url/blade-auth/register',
    method: 'post',
    params: form
  })
}

// 获取验证码
export const getRegisterCode = (form) => {
  return request({
    url: `/url/blade-auth/send/phone/code?captchaVerification=${form.captchaVerification}&phone=${form.phone}&type=${form.type}`,
    method: 'post',
    data: form
  })
}

// 忘记密码
export const getForget = (form) => {
  return request({
    url: '/url/blade-auth/forget/password',
    method: 'get',
    params: form
  })
}

// 登出
export const getLogout = (form) => {
  return request({
    url: '/url/blade-auth/logout',
    method: 'post',
    data: form
  })
}