<template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        一分钟测量入选<span>高被引论文</span>
                    </div>
                    <div class="center">
                        引用次数的多少，对于成为HighlyCitedPaper（高被引论文）来说，
                        没有统一的标准，入选高被引论文与期刊、发表时间有关系。
                    </div>
                    <div class="btn">
                        <el-button type="primary" class="top_btn" @click="openRandomDialog">免费测量</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/appraisal_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="title_box">
                <div class="icon">
                    <i class="el-icon-s-opportunity"></i>
                </div>
                <div class="text">
                    请完善论文基本信息，实时测量高被引论文标准
                </div>
                <div class="icon">
                    <i class="el-icon-s-opportunity"></i>
                </div>
            </div>
            <div>

                <el-card class="box_card">
                    <img src="../../assets/image/award.webp" alt=""
                        style="width: 150px; position: absolute; top: 0; left: 0;">
                    <div class=" title">
                        一键查询高被引差距
                        <div class="line">

                        </div>
                    </div>
                    <div class="form_box">
                        <el-form label-position="left" label-width="80px" :model="form" class="form" ref="form"
                            :rules="rules">
                            <el-form-item label="论文题目" class="form_item" prop="title">
                                <el-input v-model="form.title"></el-input>
                            </el-form-item>
                            <el-form-item label="联系邮箱" class="form_item" prop="email">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                            <el-form-item label="Doi" class="form_item" prop="doi">
                                <el-input v-model="form.doi"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" class="form_item" prop="phone">
                                <el-input v-model="form.phone"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="hint">
                            * 高被引论文阈值每两个月更新一次 *
                        </div>
                    </div>
                    <div class="btn_box">
                        <el-button type="primary" class="btn">开始测量</el-button>
                    </div>
                    <div class="po_ab">
                        <!-- <img src="../../assets/image/QR_bg.png" alt="" style="width: 331px;  bottom: 0px;right: 20px; "> -->
                        <div>
                            <img src="../../assets/image/app_QR.png" alt=""
                                style="width: 126px;  bottom: 0px;right: 90px;">
                            扫码下载高被引APP
                        </div>
                    </div>
                </el-card>
            </div>
        </main>
        <div class="bottom">
            <div class="content">
                <div class="small">
                    已累计为全球
                </div>
                <div class="big">
                    <span> 6,300+</span> 篇论文完成免费测量成为高被引差距！
                </div>
            </div>
            <div>
                <img src="@/assets/image/cepin_bottom.png" alt="">
            </div>
        </div>
        <journal-recommend></journal-recommend>
        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="1" @cancel="() => dialogVisible = false"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import dialogQR from "@/components/common/dialogQR.vue";

export default {
    components: {
        journalRecommend,
        dialogQR
    },
    data() {
        return {
            dialogVisible: false,
            labelPosition: 'right',
            form: {
                title: '',
                email: '',
                phone: '',
                doi: ''
            },
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] }
                ],
                title: [
                    { required: true, message: '请输入论文标题', trigger: 'blur' },
                ],
                doi: [
                    { required: true, message: '请输入Doi', trigger: 'blur' },
                ]
            }
        };
    },
    created() {

    },
    mounted() {


    },
    methods: {
        openRandomDialog() {
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #121E2C;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;

    .top_center {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 657px;
        height: 405px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;

            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;

    .title_box {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        padding: 20px;
        margin-top: 80px;
        font-weight: bold;

        .icon {
            font-size: 20px;
            color: #3470ff;
            padding: 0 20px;
        }
    }

    .box_card {
        width: 100%;
        height: 500px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;

        .title {
            width: 366px;
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 12px;
            margin-left: 87px;
            border-bottom: 2px solid #ccc;
            position: relative;

            .line {
                width: 132px;
                height: 2px;
                background-color: #3470ff;
                position: absolute;
                bottom: -2px;
            }
        }

        .form_box {
            // width: 100%;
            margin: 0 132px;

            .form {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 20px;
                // margin: 0 132px;

                .form_item {
                    width: calc(calc(100% - 100px) /2);
                }
            }

            .hint {
                padding: 20px 40px;
                color: #c6c6c6;
                font-size: 14px;
            }

        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 243px;
                height: 43px;
                background-color: #3470ff;
                border-radius: 4px;
                border: #3470ff;
            }
        }
    }
}

.bottom {
    background-color: #3470FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px 0;

    .content {
        // width: 1200px;
        // margin: 0 auto;
        box-sizing: border-box;
        padding: 65px 40px;
        color: #c5d3f8;
        line-height: 40px;
        margin-right: 300px;

        .small {
            font-size: 14px;
        }

        .big {
            font-size: 20px;
            font-weight: bold;

            >span {
                font-size: 36px;
                color: #fcfba0;
            }
        }
    }

    img {
        width: 234px;
        // height: 182px;
    }
}

.po_ab {

    width: 331px;
    height: 315px;
    position: absolute;
    right: 0;
    bottom: -16%;
    flex-direction: column;
    background-image: url('@/assets/image/QR_bg.png');
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;

    div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 40px;
        color: #666666;
        font-size: 14px;
    }
}
</style>
<style scoped></style>