<template>
    <div>
        <div v-if="imageType === 1" class="image-container">
            <img :src="firstImage" alt="Daily Image 1">
        </div>
        <div v-else-if="imageType === 2" class="image-container">
            <img :src="secondImage" alt="Daily Image 2">
        </div>
        <div v-else-if="imageType === 3" class="image-container">
            <img :src="thirdImage" alt="Daily Image 3">
        </div>
        <div v-else-if="imageType === 13" class="image-container">
            <img :src="developImage" alt="Daily Image 13">
        </div>
        <div v-else-if="imageType === 14" class="image-container">
            <img :src="internationalImage" alt="Daily Image 13">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageType: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            images: [
                require('@/assets/QR_img/傅.png'),
                require('@/assets/QR_img/黄.jpg'),
                require('@/assets/QR_img/李.jpg'),
                require('@/assets/QR_img/丘.jpg'),
                require('@/assets/QR_img/沈-1.jpg'),
                require('@/assets/QR_img/孙.png'),
                require('@/assets/QR_img/王.png'),
                require('@/assets/QR_img/徐.jpg'),
                require('@/assets/QR_img/余.jpg'),
                require('@/assets/QR_img/张.jpg'),
                require('@/assets/QR_img/张古存.jpg'),
                require('@/assets/QR_img/郑.jpg'),
                require('@/assets/QR_img/郭.jpg'),
                require('@/assets/QR_img/王-1.jpg'),
            ],
            firstIndex: 0,
            secondIndex: 1,
            thirdIndex: 2 // 新增第三张图片的索引
        };
    },
    computed: {
        firstImage() {
            return this.images[this.firstIndex];
        },
        secondImage() {
            return this.images[this.secondIndex];
        },
        thirdImage() {
            return this.images[this.thirdIndex];
        },
        internationalImage() {
            return this.images[13]
        },
        developImage() {
            return this.images[12]
        }
    },
    mounted() {
        const dayOfMonth = new Date().getDate();
        // 计算今天应该显示的三张图片的索引
        this.firstIndex = (dayOfMonth - 1) % 12;
        this.secondIndex = (this.firstIndex + 1) % 12;
        this.thirdIndex = (this.firstIndex + 2) % 12; // 计算第三张图片的索引
    }
};
</script>

<style scoped>
.image-container {
    width: 100%;
    height: 100%;
}

img {
    width: 100%;
    height: 100%;
}
</style>