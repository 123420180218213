import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/pages/user/login'
Vue.use(VueRouter)


// 高被引
import Trade from '@/pages/information/trade.vue'
import About from '@/pages/about/about.vue'
import Index from '@/pages/index/index.vue'
import Cooperation from '@/pages/cooperation/cooperation.vue'
import Appraisal from '@/pages/appraisal/appraisal.vue'
import PaperPromotion from '@/pages/promotion/paperPromotion.vue'
import PersonalPromotion from '@/pages/promotion/personalPromotion.vue'
import JournalPromotion from '@/pages/promotion/journalPromotion.vue'
import Consult from '@/pages/promotion/consult.vue'
import PaperReport from '@/pages/report/paperReport.vue'
import Prove from '@/pages/report/prove.vue'
import Privacy from '@/pages/common/privacy.vue'
import UserAgreement from '@/pages/common/userAgreement.vue'

export default new VueRouter({
	routes: [
		{
			path: "",
			name: "",
			component: () => import("@/pages/index/index.vue"),
		},

		{
			path: "/",
			redirect: "/index"
		},

		{
			path: "/index",
			name: 'index',
			component: Index,
		},
		{
			path: "/login",
			component: Login,
		},
		{
			path: '/about',
			name: 'about',
			component: About
		},

		{
			path: '/trade',
			name: 'trade',
			component: Trade
		},
		{
			path: '/cooperation',
			name: 'cooperation',
			component: Cooperation
		},
		{
			path: '/appraisal',
			name: 'appraisal',
			component: Appraisal
		},
		{
			path: '/paperPromotion',
			name: 'paperPromotion',
			component: PaperPromotion
		},
		{
			path: '/personalPromotion',
			name: 'personalPromotion',
			component: PersonalPromotion
		},
		{
			path: '/journalPromotion',
			name: 'journalPromotion',
			component: JournalPromotion
		},
		{
			path: '/consult',
			name: 'consult',
			component: Consult
		},
		{
			path: '/paperReport',
			name: 'paperReport',
			component: PaperReport
		},
		{
			path: '/prove',
			name: 'prove',
			component: Prove
		},
		{
			path: "/privacy",
			name: 'privacy',
			component: Privacy
		},
		{
			path: "/userAgreement",
			name: 'userAgreement',
			component: UserAgreement
		}

	]
})


