<template>

</template>
<script>

</script><template>
    <div class="box">
        <!-- 头部图片 -->
        <div class="top_img">
            <!-- <img src="@/assets/image/top_img.jpg" alt=""> -->
            <div class="top_center">
                <div class="top_box">
                    <div class="title">
                        论文引文<span>追踪报告</span>
                    </div>
                    <div class="center">
                        <div>
                            <div class="describe">
                                <div class="icon">
                                    <i class="el-icon-check"></i>
                                </div>
                                当前被引分析
                            </div>
                            <div class="describe">
                                <div class="icon">
                                    <i class="el-icon-check"></i>
                                </div>
                                差距分析
                            </div>
                        </div>
                        <div>
                            <div class="describe">
                                <div class="icon">
                                    <i class="el-icon-check"></i>
                                </div>
                                阈值预则
                            </div>
                            <div class="describe">
                                <div class="icon">
                                    <i class="el-icon-check"></i>
                                </div>
                                冲刺建议
                            </div>
                        </div>
                    </div>
                    <div class="btn">
                        <el-button class="top_btn" @click="openRandomDialog">立即下单</el-button>
                    </div>
                </div>
                <div class="img_box">
                    <img src="@/assets/image/report_bg.png" alt="">
                </div>
            </div>
        </div>
        <main>
            <div class="what">
                <div class="title">
                    <div class="icon">
                        <img src="@/assets/image/11_07.png" alt="">
                    </div>
                    什么是论文引文追踪报告？
                </div>
                <div class="content">
                    学术引用跟踪报告服务，旨在为您提供深入的引文分析，帮助您了解您的研究在学术界的表现，并为您的未来研究提供战略性建议。
                </div>
            </div>
            <div class="introduce">
                <div>
                    报告包含
                </div>
                <div>
                    <p>
                        （1）当前被引分析：我们将对您发表的论文进行实时监控，统计其被引用的次数。
                    </p>
                    <p>
                        （2）差距分析：通过对比您的有效引用与ESI高被引论文的差距。
                    </p>
                    <p>
                        （3）阈值预则：我们将基于历史数据和当前趋势，预测未来一段时间内ESI阈值涨幅情况。
                    </p>
                    <p>
                        （4）冲刺建议：结合以上分析，我们将提供定制化的建议，帮助您优化推广策略，以期在未来实现论文影响力提升的目标。
                        推动学术发展，促进学术成就。欢迎垂询详情或订购报告。
                    </p>
                </div>
                <div class="left_top top"></div>
                <div class="top_left top"></div>
                <div class="left_top bottom"></div>
                <div class="top_left bottom"></div>
            </div>
            <div class="cost">
                <div class="title_box">
                    <div class="title">
                        费用定价说明
                    </div>
                    <div class="describe">
                        报告每年六次，每次ESI数据库更新之后15日内出具完毕
                    </div>
                </div>
                <div class="costList">
                    <div class="item pointer" @click="getActive(1)" :class="{ active: active === 1 }">
                        <div class="text">
                            单次合作5篇以下
                        </div>
                        <div class="money">
                            <span class="money_icon">¥</span> 200 <span class="unit">/篇/年</span>
                        </div>
                    </div>
                    <div class="item pointer" @click="getActive(2)" :class="{ active: active === 2 }">
                        <div class="text">
                            合作5篇优惠价
                        </div>
                        <div class="money">
                            <span class="money_icon">¥</span> 799 <span class="unit">/年</span>
                        </div>
                    </div>
                    <div class="item pointer" @click="getActive(3)" :class="{ active: active === 3 }">
                        <div class="text">
                            超过5篇每篇
                        </div>
                        <div class="money">
                            <span class="money_icon">¥</span> 100 <span class="unit">/篇/年</span>
                        </div>
                    </div>
                </div>
                <div class="btn_box">
                    <el-button type="primary" class="btn" @click="() => dialogVisible0 = true">立即下单</el-button>
                </div>
            </div>
        </main>
        <journal-recommend></journal-recommend>
        <div class="bottom_box">
            <div class="section">
                <div class="left">
                    <div class="title">
                        高被引论文引用追踪报告
                    </div>
                    <div class="text">
                        ESI- Highly Cited Paper Citation Tracking & Prediction
                    </div>
                    <el-button plain class="left_btn">免费获取</el-button>
                </div>
                <div class="right">
                    <img src="@/assets/image/report_bg.png" alt="">
                </div>
            </div>
        </div>
        <dialogQR :dialogVisible="dialogVisible0" :path="$route.path" :index="0" @cancel="() => dialogVisible0 = false"
            @confirm="() => dialogVisible0 = false">
        </dialogQR>

        <dialogQR :dialogVisible="dialogVisible" :path="$route.path" :index="1" @cancel="cloneRandomDialog"
            @confirm="() => dialogVisible = false">
        </dialogQR>
    </div>
</template>
<script>
import journalRecommend from "@/components/common/journalRecommend.vue"
import imageList from "@/components/common/imageList.vue"
import dialogQR from "@/components/common/dialogQR.vue";

export default {
    components: {
        journalRecommend,
        imageList,
        dialogQR
    },
    data() {
        return {
            dialogVisible0: false,
            dialogVisible: false,
            activeName: 'first',
            active:1,
        }
    },
    created() {

    },
    mounted() {


    },
    methods: {
        getActive(index){
            this.active = index
        },
        openRandomDialog() {
            this.dialogVisible = true
        },
        cloneRandomDialog() {
            this.dialogVisible = false
        }
    }
}
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 20px;
    /* 调整根据你的需要固定的位置 */
}

.box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: hsl(240, 33.33%, 98.82%);
    box-sizing: border-box;
}

// 头部图片
.top_img {
    width: 100%;
    height: 486px;
    // background-image: url('@/assets/image/top_img.jpg');
    background-color: #3470FF;
    background-size: cover;
    /* 让背景图片铺满整个元素 */
    background-position: center;
    /* 图片居中对齐 */
    background-repeat: no-repeat;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top_center {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .img_box {
        width: 280px;
        height: 345px;
        margin-right: 100px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .top_box {
        /*   margin: 150px;
        margin-left: 200px; */
        color: #fff;

        .title {
            font-size: 44px;
            color: #c5d3f8;

            span {
                color: #fcfba0;
            }
        }

        .center {
            width: 524px;
            padding: 20px 0;
            display: flex;

            >div {
                margin-right: 10px;
            }

            .describe {
                margin: 12px 0;
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #fff;

                .icon {
                    // width: 18px;
                    // height: 18px;
                    padding: 3px;
                    display: block;
                    border-radius: 50%;
                    background-color: #2FC54F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    i {
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
        }

        .top_btn {
            // background-color: #3470FF;
            // border: #3470FF;
            width: 156px;
            height: 56px;
        }
    }
}

main {
    width: 1200px;
    margin: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .what {
        width: 100%;
        background-color: rgba(237, 242, 253, .5);
        border-radius: 0px 80px 0px 80px;
        padding: 50px;

        .title {
            display: flex;
            align-items: center;

            font-size: 28px;
            font-weight: bold;
            color: #434343;
            padding-bottom: 20px;

            .icon {
                margin-right: 10px;
            }
        }

        .content {
            font-size: 16px;
            color: #979797;
        }
    }

    .introduce {
        width: 100%;
        position: relative;
        margin: 50px 0;
        color: #fff;
        padding: 30px;
        background-color: #434343;
        line-height: 30px;

        .left_top {

            width: 200px;
            height: 5px;
            background-color: #3470ff;

        }

        .top_left {
            width: 5px;
            height: 98px;
            background-color: #3470ff;

        }

        .top {
            position: absolute;
            left: 0;
            top: 0;
        }

        .bottom {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .cost {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title_box {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .title {
                font-size: 30px;
                font-weight: bold;
                color: #3470ff;
                padding-bottom: 18px;
            }

            .describe {
                font-size: 16px;
                font-style: italic;
                color: #434343;
            }
        }
    }

    .costList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 40px;

        .item {
            width: calc(calc(100% - 200px)/3);
            margin: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border-radius: 10px;
            border: solid 1px #eeeeee;
            box-shadow: 0 0 5px 0px rgba(147, 138, 138, 0.2);
            transition: all .3s;

            .text {
                padding: 20px;
                color: #434343;
                font-size: 20px;
                font-weight: bold;
                transition: all .3s;
            }

            .money {
                font-size: 36px;
                font-weight: bold;
                color: #3470ff;

                .money_icon {
                    font-size: 24px;
                    color: #434343;
                }

                .unit {
                    font-size: 18px;
                    color: #434343;
                }
            }
        }

        .active {
            border: 1px solid #3470ff !important;

            .text {
                color: #3470ff;

            }
        }
    }

    .btn {
        width: 308px;
        height: 56px;
        background-color: #3470ff;
        border: #3470ff;
        font-size: 16px;
    }

    .service {
        display: flex;
        justify-content: center;
        // margin-top: 20px;
        padding: 30px;

        >div {
            width: 156px;
            height: 156px;
        }

        .left_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            line-height: 30px;
            color: #666666;
            font-size: 14px;

            .text {
                font-size: 16px;
                color: #434343;
            }

            span {
                font-size: 54px;
            }
        }

        .right_box {
            // border: 1px solid #000;
        }
    }
}

.bottom_box {
    width: 100%;
    background-image: linear-gradient(-90deg,
            #3470ff 0%,
            #0d42c2 100%),
        linear-gradient(#3470ff,
            #3470ff);
    background-blend-mode: normal,
        normal;

    .section {
        width: 1200px;
        margin: 0 auto;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;

            .title {
                font-size: 44px;
                color: #fff;
                font-weight: bold;
                // padding: 20px;
            }

            .text {
                padding-bottom: 20px;
                font-size: 16px;
                color: #c5d3f8;
            }

            .left_btn {
                width: 156px;
                height: 42px;
            }
        }

        .right {

            width: 280px;
            height: 345px;

            img {
                width: 100%;
                height: 100%;

            }
        }
    }
}
</style>
<style scoped></style>